import React, { Component } from 'react';
import Fade from "react-reveal/Fade";
import { HashLink } from 'react-router-hash-link';

class Opportunities extends Component {
    render() {
        return (
            <div className="row justify-content-center pb-0 g-0" id="features">
                {/*<SeoTitle Title='Track productivity, attendance, and all your team’s efforts on a single platform!' TitleP='Plan and organize projects in a way that’s best suitable for your team, empowering them to self-manage their tasks and time.' />*/}
                <div className="col-md-6 feature-card">
                    <Fade bottom cascade>
                        <div className="iitem p-0 mx-3">
                            <div className="seo_service_item sap-card p-0 row g-0">
                                <div className="card-content col-lg-6 col-12">
                                    <h4 className="mt-3 font-15 font-weight-semibold mb-3 position-relative">Unleashing Flexibility in Lease Administration</h4>
                                    <p className="m-0 font-14">Explore RealCube’s Lease Management System, a comprehensive solution designed to streamline lease administration. Welcome RealCube, which simplifies real estate lease management, data management, rent cost analysis and</p>
                                    <div class="read-more">
                                        <HashLink to="/Features#EmployeeManagement" className="read-more_btn" >Learn More...</HashLink>
                                    </div>
                                </div>
                                <div className="service-icon col-lg-6 col-12">
                                    <img className="w-100" src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube/sap-btp/lease-section.webp" width="680" height="200" alt="Employee Management" />
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className="col-md-6 feature-card">
                    <Fade bottom cascade>
                        <div className="iitem p-0 mx-3">
                            <div className="seo_service_item sap-card p-0 row">
                                <div className="card-content col-lg-6 col-12">
                                    <h4 className="mt-3 font-15 font-weight-semibold mb-3 position-relative">Cloud-based SAP S/4HANA solution tailored for Real Estate</h4>
                                    <p className="m-0 font-14">RealCube Cloud revolutionizes property management with its data-centric approach, leveraging SAP BTP to integrate seamlessly with SAP S/4HANA Cloud. This unified platform enhances decision-making, regulatory compliance, and</p>
                                    <div class="read-more">
                                        <HashLink to="/Features#EmployeeManagement" className="read-more_btn" >Learn More...</HashLink>
                                    </div>
                                </div>
                                <div className="service-icon col-lg-6 col-12">
                                    <img className="w-100" src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube/sap-btp/lease-section.webp" width="680" height="200" alt="Employee Management" />
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                {/*<div className="body_wrapper">*/}
                {/*<h1 className='py-5 mt-5'>Time and attendance management system</h1>*/}
                {/*/!*<Card style={{ width: '18rem' }}>*!/*/}
                {/*/!*<Card.Img variant="top" src="holder.js/100px180" />*!/*/}
                {/*/!*<Card.Body>*!/*/}
                {/*/!*<Card.Title>Card Title</Card.Title>*!/*/}
                {/*/!*<Card.Text>*!/*/}
                {/*/!*Some quick example text to build on the card title and make up the*!/*/}
                {/*/!*bulk of the card's content.*!/*/}
                {/*/!*</Card.Text>*!/*/}
                {/*/!*<Button variant="primary">Go somewhere</Button>*!/*/}
                {/*/!*</Card.Body>*!/*/}
                {/*/!*</Card>*!/*/}
                {/*</div>*/}
            </div>
        )
    }
};

export default Opportunities;