import React from 'react';
import Fade from 'react-reveal/Fade';

const FeatureList = () => {
    return (
        <div className="body_wrapper">
            {/*<CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>*/}
            {/*<FeatureCard featureClass="feature_area employee-management pt-3 pb-0 font-36" imgName="innerpage/engage Pages image/Feature page images/Group 75789.png" Ptitle="Employee Management "  Pdescription="Manage employee activities with a channel of clear communication with the Employee Management feature. The system creates an environment that helps you review the permissions raised by employees and approve them so lags in workflow are minimised. You can also track and assess employee performance, employee grievances and employee suggestions as put forth."  PdescriptionTwo=" Keep a track of employee absenteeism and their clock in/clock out timings to maintain uniformity and keep track of their overtimes while chalking out performance incentives. The feature also helps you keep tabs on employee location during their stipulated hours of work." />*/}
            {/*<Footer FooterData={FooterData}/>*/}
            <section className="seo_features_about sec_pad pb-0 about-us">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center about-content pt-5">
                            <Fade bottom cascade>
                                <div className="seo_features_content justify-content-start">
                                    <h1>Engage</h1>
                                    <p>Engage is a simple and efficient employee management tool, it helps you to plan and organize in a way which is best suitable for you and your team. Engage is aimed at, giving you and your team the ability to manage your self by collaborating with watch other. To this extant engage takes care of all small miscellaneous tasks associated with managing, by providing you relevant information on your fingertips and gives you and your team the opportunity to focus on the work which matters.</p>
                                    <p>Our aim with engage is to provide the best working experience which is possible to you and your team, in or out of the work place. </p>
                                </div>
                            </Fade>
                        </div>
                        <div className="col-lg-6 m-0">
                            <div className="seo_features_img seo_features_img_two about-pic pt-5">
                                {/*<div className="round_circle"></div>*/}
                                {/*<div className="round_circle two"></div>*/}
                                <img src={require('../img/AboutUs/About_Us_banner.webp')} width="570" height="469" alt="About Us Banner" className="about_picc" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default FeatureList;