import React from 'react';
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import CustomNavbar from '../components/CustomNavbar';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';
import Banner from '../components/TimeManagementSystemCopyTwo/Banner';
import Excellence from '../components/TimeManagementSystemCopyTwo/Excellence';
import Experience from '../components/TimeManagementSystemCopyTwo/Experience';
import Opportunities from "../components/TimeManagementSystemCopyTwo/Opportunities";
import RelatedArticles from "../components/TimeManagementSystemCopyTwo/RelatedArticles";
import Subscribe from '../components/Subscribe';
import GetInTouch from '../components/Features';
const TimeManagementSystemCopyTwo = () => {

    return (
        <>
            <Helmet>
                <title>Engage App Cloud - Time and attendance management system</title>
                <meta
                    name="description"
                    content="Engage is a simple and efficient employee management tool, it helps you to plan and organize in a way which is best suitable for you and your team."
                />
                <meta
                    name="author"
                    content="Engage"
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Engage App Cloud - Time and attendance management system" />
                <meta property="og:description" content="Engage is a simple and efficient employee management tool, it helps you to plan and organize in a way which is best suitable for you and your team." />
                <meta property="og:url" content="https://www.engageapp.cloud/" />

                <meta property="og:site_name" content="Engage" />
                <meta property="og:image" content="" />
                <meta property="og:image:width" content="200" />
                <meta property="og:image:height" content="200" />
                <meta property="og:image:alt" content="Engage App Cloud - Time and attendance management system" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Engage is a simple and efficient employee management tool, it helps you to plan and organize in a way which is best suitable for you and your team." />
                <meta name="twitter:title" content="Engage App Cloud - Time and attendance management system" />
                <meta name="twitter:site" content="https://www.engageapp.cloud/" />
                <meta name="twitter:creator" content="@engage" />
            </Helmet>
            <div className="body_wrapper sap-text">
                <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
                <Banner />
                <div className="opportunities mb-lg-4 mb-3 mt-3 mt-lg-5 mt-md-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <Fade bottom cascade>
                                    <div>
                                        <h2 className="border-left-lems position-relative font-weight-bold mb-3 mb-md-5 pl-4">Key Features</h2>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <Opportunities />
                    </div>
                </div>
                <Excellence />
                <Subscribe />
                <GetInTouch />
                <Footer FooterData={FooterData} />
            </div>
        </>
    )
};

export default TimeManagementSystemCopyTwo;