import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
// import SeoTitle from '../Title/SeoTitle';
import Fade from 'react-reveal/Fade';

import Slider from 'react-slick/';
import Sticky from 'react-stickynode';

import { Link } from 'react-scroll'

import { HashLink } from 'react-router-hash-link';
const MenuNavigation = () => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        arrows: true,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (

        <React.Fragment>

            <Sticky top={70} innerZ={9998} activeClass="navbar_fixed bg-light features">
                <section className="seo_service_area feature_navigation" id="features">
                    <div className="container py-2">
                        {/*<SeoTitle Title='Track productivity, attendance, and all your team’s efforts on a single platform!' TitleP='Plan and organize projects in a way that’s best suitable for your team, empowering them to self-manage their tasks and time.'/>*/}



                        <Slider {...settings} className="feature-card case_studies_slider py-0" style={{ display: 'flex', listStyle: 'none', justifyContent: 'space-around' }}>
                            <div className="iitem pt-2 feature_navigation_item">
                                {/* <NavLink title="Employee management" to='/employee-management'>
                                    <h6 className="p-2 nav_menu active">Employee Management</h6>
                                </NavLink> */}
                                 <Link className="p-2 nav_menu"  to="EmployeeManagement" spy={true} smooth={true}>Employee Management</Link>
                                {/*<HashLink to="/Features#EmployeeManagement">Employee Management</HashLink>*/}
                            </div>
                            <div className="iitem pt-2">
                                {/* <NavLink title="Location management" to='/location-management'>
                                    <h6 className="p-2 nav_menu">Location management</h6>
                                </NavLink> */}
                                 <Link className="p-2 nav_menu" to="LocationManagement" spy={true} smooth={true}>Location management</Link>
                                {/*<HashLink to="/Features#LocationManagement">Location Management</HashLink>*/}
                            </div>
                            <div className="iitem pt-2">
                                {/* <NavLink title="Shift Management" to='/shift-management'>
                                    <h6 className="p-2 nav_menu">Shift Management</h6>
                                </NavLink> */}
                                 <Link className="p-2 nav_menu" to="ShiftManagement" spy={true} smooth={true}>Shift Management</Link>
                                {/*<HashLink to="/Features#ShiftManagement">Shift Management</HashLink>*/}
                            </div>
                            <div className="iitem pt-2">
                                {/* <NavLink title="Role Management" to='/role-management'>
                                    <h6 className="p-2 nav_menu">Role Management</h6>
                                </NavLink> */}
                                 <Link className="p-2 nav_menu" to="RoleManagement" spy={true} smooth={true}>Role Management</Link>
                                {/*<HashLink to="/Features#RoleManagement">Role Management</HashLink>*/}
                            </div>
                            <div className="iitem pt-2">
                                {/* <NavLink title="Feature Access Management" to='/feature-access-management'>
                                    <h6 className="p-2 nav_menu">Feature Access Management</h6>
                                </NavLink> */}
                                 <Link className="p-2 nav_menu" to="FeatureAccessManagement" spy={true} smooth={true}>Feature Access Management</Link>
                                {/*<HashLink to="/Features#FeatureAccessManagement">Feature Access Management</HashLink>*/}
                            </div>
                            <div className="iitem pt-2">
                                {/* <NavLink title="Team Management" to='/team-management'>
                                    <h6 className="p-2  nav_menu">Team Management</h6>
                                </NavLink> */}
                                <Link className="p-2 nav_menu" to="TeamManagement" spy={true} smooth={true}>Team Management</Link>
                                {/*<HashLink to="/Features#TeamManagement">Team Management</HashLink>*/}
                            </div>
                            <div className="iitem pt-2">
                                {/* <NavLink title="Leave Management" to='/leave-management'>
                                    <h6 className="p-2 nav_menu">Leave Management</h6>
                                </NavLink> */}
                                 <Link className="p-2 nav_menu" to="LeaveManagement" spy={true} smooth={true}>Leave Management</Link>
                                {/*<HashLink to="/Features#LeaveManagement">Leave Management</HashLink>*/}
                            </div>
                            <div className="iitem pt-2">
                                {/* <NavLink title="Reports" to='/reports'>
                                    <h6 className="p-2 nav_menu">Reports</h6>
                                </NavLink> */}
                                 <Link className="p-2 nav_menu" to="ReportsManagement" spy={true} smooth={true}>Reports</Link>
                                {/*<HashLink to="/Features#ReportsManagement">Reports</HashLink>*/}
                            </div>
                        </Slider>
                    </div>
                </section>
            </Sticky>
        </React.Fragment>
    )
}

export default MenuNavigation;
