import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FeatureCard from '../components/Innerpages/FeatureList';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';

const FeatureList = () => {
    return (
        <div className="body_wrapper">
            {/*<CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>*/}
            <FeatureCard featureClass="feature_area font-36" imgName="innerpage/engage Pages image/Feature page images/Mask Group 54.png" Ptitle="Feature Access Management"  Pdescription="The Feature Access Management tool performs a function similar to that of the Role Management feature, but offers a greater level of flexibility. Here, the individual that holds a position can provide the access to the features that in an ordinary circumstance would not be accessible to another individual of a different post."  PdescriptionTwo="This feature can be leveraged in case of emergency leaves to make sure tasks are carried out according to set timelines. The individuals responsible for them experience an ease and focus on personal issues at hand. " />
            {/*<Footer FooterData={FooterData}/>*/}


        </div>
    )
}
export default FeatureList;