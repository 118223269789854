import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import IrisIDPage from '../components/IrisIDPage';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';

const IrisID = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>
            <IrisIDPage />
            <Footer FooterData={FooterData}/>
        </div>
    )
}
export default IrisID;