import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FeatureCard from '../components/Innerpages/FeatureList';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';

const FeatureList = () => {
    return (
        <div className="body_wrapper">
            {/*<CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>*/}
            <FeatureCard featureClass="feature_area font-36" imgName="innerpage/engage Pages image/Feature page images/Group 75958.png" Ptitle="Role Management "  Pdescription="The main function of the Role Management feature revolves around providing a set of access features that come with a role or position held, higher the position, more are the features associated with it. It also guards unauthorized persons from unlocking the same, while maintaining a constant check."  PdescriptionTwo="  It gives the members of the team a clear picture of the powers delegated to each person depending on their role, so internal collaboration is bettered."  />
            {/*<Footer FooterData={FooterData}/>*/}
        </div>
    )
}
export default FeatureList;