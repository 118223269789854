import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import ScheduleDemoForm from '../components/ScheduleDemoForm';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';
import { Helmet } from "react-helmet";

const ScheduleDemo = () => {
    return(
        <>
            <Helmet>

                <title>Schedule for a Free Demo of Engage App Cloud</title>

                <meta
                    name="description"
                    content="Schedule a demo of EngageApp Cloud, an effective employee management software system to track attendance, login time, leaves, payroll, teams management and much more."
                />
                <meta
                    name="author"
                    content="Engage"
                />

                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Schedule for a Free Demo of Engage App Cloud" />
                <meta property="og:description" content="Schedule a demo of EngageApp Cloud, an effective employee management software system to track attendance, login time, leaves, payroll, teams management and much more." />
                <meta property="og:url" content="https://www.engageapp.cloud/" />

                <meta property="og:site_name" content="Engage" />
                <meta property="og:image" content="" />
                <meta property="og:image:width" content="200" />
                <meta property="og:image:height" content="200" />
                <meta property="og:image:alt" content="Schedule for a Free Demo of Engage App Cloud" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Schedule a demo of EngageApp Cloud, an effective employee management software system to track attendance, login time, leaves, payroll, teams management and much more." />
                <meta name="twitter:title" content="Schedule for a Free Demo of Engage App Cloud" />
                <meta name="twitter:site" content="https://www.engageapp.cloud/" />
                <meta name="twitter:creator" content="@engage" />

            </Helmet>
            <div className="body_wrapper">
                <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>
                <ScheduleDemoForm />
                <Footer FooterData={FooterData}/>
            </div>
        </>
    )
};

export default ScheduleDemo;