import React from 'react';


const ScheduleDemoForm =()=>{
    return(
        <section className="sign_in_area bg_white sec_pad">
            <div className="container">
                <div className="schedule_form mt_70">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="schedule_img">
                                <img src={require('../img/schedule_demo.png')} width="570" height="528" alt="Schedule a Demo Banner"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="login_info schedule_login">
                               <div className="text-center">
                                <img src={require("../img/signup_logo.png")} width="207" height="49" alt="Engage Blue Logo"/>
                                <h1 className="mb_40">Schedule a Demo</h1>
                               </div>
                                <form action="#" className="common_form">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6">
                                            <label>Full Name</label>
                                            <input type="text" className="form-control" placeholder="Enter your full name" name="fname" required />
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <label>Company Name</label>
                                            <input type="text" className="form-control" placeholder="Enter your company name" name="email" required />
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <label> Mobile Number </label>
                                            <input type="number" className="form-control" placeholder="Enter your mobile number" name="phone"  required />
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <label>Email ID</label>
                                            <input type="email" className="form-control" placeholder="Email ID" name="company" required />
                                        </div>
                                        <div className="col submit-btn">
                                            <button type="submit" name="submit" className="btn_three">Schedule</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ScheduleDemoForm;