import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import HealthPage from '../components/Innerpages/HealthComponent';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';

const Education = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>
            <HealthPage />
            <Footer FooterData={FooterData}/>
        </div>
    )
}
export default Education;