import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal/';
import {NavLink} from 'react-router-dom';

class FeatureCard extends Component {
    render(){
        var {Ptitle, Pdescription,featureClass, imgName,PdescriptionTwo} = this.props;
        return(
            <section className={`${featureClass}`}>
                <div className="container">
                    <div className="row justify-content-end align-items-center pb-0">

                        <div className="col-xs-12 col-lg-6 col-md-12 featurelist-title">
                            <Reveal effect="fadeInUp">
                                <h2 className="mb-3 fnt18">{Ptitle}</h2>
                            </Reveal>
                            <Reveal effect="fadeInUp" duration={900}>
                                <p className="feature_para">{Pdescription}</p>
                                <p className="feature_para">{PdescriptionTwo}</p>
                            </Reveal>
                        </div>
                        <div className="col-xs-12 col-lg-6 col-md-12 justify-content-end">
                            <Reveal effect="fadeInUp" duration={900}> <img className="img-fluid feature_img" src={require ("../../img/" + imgName)} alt="Image"/></Reveal>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}
export default FeatureCard;