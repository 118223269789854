import React from "react";
import Fade from "react-reveal/Fade";
import RequestDemoModal from "../RequestDemoModal/RequestDemoModal";

const Banner = () => {
  return (
    <div className="body_wrapper">
      <section className="sec_pad time-mng-system lp-banner pb-0">
        <div className="container pb-md-4 h-100">
          <div className="h-100 py-lg-3">
            <div className="row align-items-center my-2">
              <div className="col-lg-7 col-md-8 align-self-center mb-4 mb-md-0">
                <Fade bottom cascade>
                  {/* <nav aria-label="breadcrumb" class="my-0 my-sm-3">
                    <ul class="list-inline breadcrumb-list bg-transparent pl-md-0 mb-0 pb-4">
                      <li class="">
                        <a class="text-dark-black" href="https://www.realcube.estate/"> Home </a>
                      </li>
                      <li class="" aria-current="page">Community Management </li>
                    </ul>
                  </nav> */}
                  <div>
                    <h1 className="pt-lg-0 pt-2 pt-sm-3 banner-txt mb-4 text-white">
                      Unleash Peak Performance: A Streamlined Employee Management Solution!
                    </h1>
                    <p className="lems-text mb-4 mb-md-4">
                      RealCube’s Lease Management System is a comprehensive solution designed to streamline lease
                      administration. Welcome RealCube, which simplifies lease management for real estate, date
                      management, and rent cost analysis to seamlessly integrate with your existing solutions.
                    </p>
                    <div className="d-flex flex-column flex-sm-row">
                      <button
                        data-toggle="modal"
                        data-target="#getDemoBtp"
                        className="btn_three mt-2 btn-hover"
                      >
                        Request a Demo
                      </button>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <RequestDemoModal />
    </div>
  );
};

export default Banner;
