import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
/*------ Pages-----*/
import { Home } from "./Pages/Home";
import DigitalMarketing from "./Pages/Digital-marketing";
import PaymentProcessing from "./Pages/Payment-processing";
import HRManagement from "./Pages/HR-Management";
import Startup from "./Pages/Startup";
import HomeCRM from "./Pages/Home-CRM";
import About from "./Pages/About";
import Certification from "./Pages/Certification";
import TimeManagementSystem from "./Pages/TimeManagementSystem";
import TimeManagementSystemCopyOne from "./Pages/TimeManagementSystemCopyOne";
import TimeManagementSystemCopyTwo from "./Pages/TimeManagementSystemCopyTwo";
import Service from "./Pages/Service";
import Process from "./Pages/Process";
import Team from "./Pages/Team";
import Portfolio2col from "./Pages/Portfolio-2col";
import Portfolio3col from "./Pages/Portfolio-3col";
import Portfoliofull4col from "./Pages/Portfolio-fullwidth-4col";
import PortfolioSingle from "./Pages/PortfolioSingle";
import Bloglist from "./Pages/Bloglist";
import BlogSingle from "./Pages/BlogSingle";
import Contact from "./Pages/Contact";
// innerpage
import ScheduleDemo from "./Pages/ScheduleDemo";
import Education from "./Pages/Education";
import Healthcare from "./Pages/Healthcare";
import Hospitality from "./Pages/Hospitality";
import Resource from "./Pages/Resource";
import IrisID from "./Pages/IrisID";
import EmployeeManagement from "./Pages/EmployeeManagement";
import LocationManagement from "./Pages/LocationManagement";
import ShiftManagement from "./Pages/ShiftManagement";
import RoleManagement from "./Pages/RoleManagement";
import FeatureAccessManagement from "./Pages/FeatureAccessManagement";
import LeaveManagement from "./Pages/LeaveManagement";
import TeamManagement from "./Pages/TeamManagement";
import Reports from "./Pages/ReportsManagement";
import Features from "./Pages/Features";
// innerpage close
import ScrollToTopRoute from "./ScrollToTopRoute";
import Landing from "./Pages/Landing";
import homesupport from "./Pages/home-support";
import homeERP from "./Pages/Home-ERP";
import homeHosting from "./Pages/HomeHosting";
import homeSecurity from "./Pages/HomeSecurity";
import homeSoftwareDark from "./Pages/Home-software-dark";
import HomeAppShowcase from "./Pages/Home-app-showcase";
import HomeCloud from "./Pages/Home-cloud";
import HomeTracking from "./Pages/Home-tracking";
import HomeEvent from "./Pages/Home-event";
import HomeChat from "./Pages/Home-chat";
import Price from "./Pages/Price";
import Faq from "./Pages/Faq";
import ServiceDetails from "./Pages/ServiceDetails";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import BlogGridPage from "./Pages/BlogGridPage";
import NotFound from "./Pages/404";
import { Helmet } from "react-helmet";
import TimeManagementSystemCopyThree from "./Pages/TimeManagementSystemCopyThree";

class App extends Component {

  componentDidMount() {
    this.props.hideLoader();
  }

  render() {
    return (
      <>
        <Helmet>
          <title> Employee Management Software - All-in-one Platform Smooth WorkForce </title>
          <meta
            name="description"
            content="Engage is one of the best and effective employee management software to evaluate their performance and effectiveness. Track time and attendance in easy steps."
          />
          <meta
            name="author"
            content="Engage"
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Employee Management Software - All-in-one Platform Smooth WorkForce" />
          <meta property="og:description" content="Engage is one of the best and effective employee management software to evaluate their performance and effectiveness. Track time and attendance in easy steps." />
          <meta property="og:url" content="https://www.engageapp.cloud/" />

          <meta property="og:site_name" content="Engage" />
          <meta property="og:image" content="" />
          <meta property="og:image:width" content="200" />
          <meta property="og:image:height" content="200" />
          <meta property="og:image:alt" content="Employee Management Software - All-in-one Platform Smooth WorkForce" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:description" content="Engage is one of the best and effective employee management software to evaluate their performance and effectiveness. Track time and attendance in easy steps." />
          <meta name="twitter:title" content="Employee Management Software - All-in-one Platform Smooth WorkForce" />
          <meta name="twitter:site" content="https://www.engageapp.cloud/" />
          <meta name="twitter:creator" content="@engage" />
        </Helmet>
        <Router>
          <Switch>
            <ScrollToTopRoute exact={true} path={"/"} component={Home} />
            <ScrollToTopRoute
              path="/digital-marketing"
              component={DigitalMarketing}
            />
            <ScrollToTopRoute
              path="/payment-processing"
              component={PaymentProcessing}
            />
            <ScrollToTopRoute path="/hr-management" component={HRManagement} />
            <ScrollToTopRoute path="/startup" component={Startup} />
            <ScrollToTopRoute path="/home-crm" component={HomeCRM} />
            <ScrollToTopRoute path="/about" component={About} />
            <ScrollToTopRoute path="/sap-SuccessFactors-Solution-Extension" component={Certification} />
            <ScrollToTopRoute path="/empower-workforce-with-best-employee-management-solutions" component={TimeManagementSystem} />
            <ScrollToTopRoute path="/employee-shift-and-location-management-software" component={TimeManagementSystemCopyOne} />
            <ScrollToTopRoute path="/employee-role-and-feature-access-management-solution" component={TimeManagementSystemCopyTwo} />
            <ScrollToTopRoute path="/leave-approval-and-team-management-software-solution" component={TimeManagementSystemCopyThree} />
            <ScrollToTopRoute path="/service" component={Service} />
            <ScrollToTopRoute path="/process" component={Process} />
            <ScrollToTopRoute path="/team" component={Team} />
            <ScrollToTopRoute path="/portfolio-2col" component={Portfolio2col} />
            <ScrollToTopRoute path="/portfolio-3col" component={Portfolio3col} />
            <ScrollToTopRoute
              path="/portfolio-fullwidth-4col"
              component={Portfoliofull4col}
            />
            <ScrollToTopRoute
              path="/portfolio-single"
              component={PortfolioSingle}
            />
            <ScrollToTopRoute path="/blog-list" component={Bloglist} />
            <ScrollToTopRoute path="/blog-single" component={BlogSingle} />
            <ScrollToTopRoute path="/blog-grid-page" component={BlogGridPage} />
            <ScrollToTopRoute path="/contact" component={Contact} />

            {/*innerpage start*/}
            <ScrollToTopRoute path="/schedule-demo" component={ScheduleDemo} />
            <ScrollToTopRoute path="/education" component={Education} />
            <ScrollToTopRoute path="/health-care" component={Healthcare} />
            <ScrollToTopRoute path="/hospitality" component={Hospitality} />
            <ScrollToTopRoute path="/resource" component={Resource} />
            <ScrollToTopRoute path="/employee-management" component={EmployeeManagement} />
            <ScrollToTopRoute path="/location-management" component={LocationManagement} />
            <ScrollToTopRoute path="/shift-management" component={ShiftManagement} />
            <ScrollToTopRoute path="/role-management" component={RoleManagement} />
            <ScrollToTopRoute path="/feature-access-management" component={FeatureAccessManagement} />
            <ScrollToTopRoute path="/leave-management" component={LeaveManagement} />
            <ScrollToTopRoute path="/team-management" component={TeamManagement} />
            <ScrollToTopRoute path="/reports" component={Reports} />
            <ScrollToTopRoute path="/features" component={Features} />


            {/*innerpage close*/}

            <ScrollToTopRoute path="/irisid" component={IrisID} />
            <ScrollToTopRoute path="/landing" component={Landing} />
            <ScrollToTopRoute path="/home-support" component={homesupport} />
            <ScrollToTopRoute path="/home-hosting" component={homeHosting} />
            <ScrollToTopRoute path="/home-erp" component={homeERP} />
            <ScrollToTopRoute path="/home-security" component={homeSecurity} />
            <ScrollToTopRoute
              path="/home-app-showcase"
              component={HomeAppShowcase}
            />
            <ScrollToTopRoute
              path="/home-software-dark"
              component={homeSoftwareDark}
            />
            <ScrollToTopRoute path="/home-cloud" component={HomeCloud} />
            <ScrollToTopRoute path="/home-tracking" component={HomeTracking} />
            <ScrollToTopRoute path="/home-event" component={HomeEvent} />
            <ScrollToTopRoute path="/home-chat" component={HomeChat} />
            <ScrollToTopRoute path="/price" component={Price} />
            <ScrollToTopRoute path="/faq" component={Faq} />
            <ScrollToTopRoute path="/service-details" component={ServiceDetails} />
            <ScrollToTopRoute path="/sign-in" component={SignIn} />
            <ScrollToTopRoute path="/sign-up" component={SignUp} />
            <ScrollToTopRoute component={NotFound} />
          </Switch>
        </Router>
      </>
    );
  }
}

export default App;
