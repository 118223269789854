import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import SeoTitle from '../Title/SeoTitle';
import Fade from 'react-reveal/Fade';

import Slider from 'react-slick/';

import { HashLink } from 'react-router-hash-link';
const Service = () => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        arrows: true,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (

        <React.Fragment>

            <section className="seo_service_area sec_pad pb-0" id="features">
                <div className="container">
                    <SeoTitle Title='Track productivity, attendance, and all your team’s efforts on a single platform!' TitleP='Plan and organize projects in a way that’s best suitable for your team, empowering them to self-manage their tasks and time.' />
                    <Slider {...settings} className="feature-card case_studies_slider">
                        <div className="iitem">
                            <HashLink title="Employee management" to="/Features#EmployeeManagement">
                                <div className="seo_service_item">
                                    <div className="service-icon">
                                        <img src={require('../../img/seo/employee_management.png')} width="67" height="67" alt="Employee Management" />
                                    </div>
                                    <h4>Employee Management</h4>
                                    <p>Management of employee by tracking actions like clock-in, away, Clock-out or absent. Editing employee details.</p>
                                    <div class="read-more">
                                        {/* <NavLink title="Get Started Now" className="read-more_btn" to="/Features/employee-management">Read More...</NavLink> */}
                                        <HashLink to="/Features#EmployeeManagement" className="read-more_btn" >Read More...</HashLink>
                                    </div>
                                </div>
                            </HashLink>
                        </div>
                        <div className="iitem">
                            <HashLink title="Location management" to="/Features#LocationManagement">
                                <div className="seo_service_item">
                                    <div className="service-icon">
                                        <img src={require('../../img/seo/location _management.png')} width="79" height="79" alt="Location management"  /></div>
                                    <h4>Location management</h4>
                                    <p>Tagging locations and defining boundaries for that location and viewing list of employees in that location.</p>
                                    <div class="read-more">
                                        {/* <NavLink title="Get Started Now" className="read-more_btn" to="/Features/location-management">Read More...</NavLink> */}
                                        <HashLink to="/Features#LocationManagement" className="read-more_btn" >Read More...</HashLink>
                                    </div>
                                </div>
                            </HashLink>
                        </div>
                        <div className="iitem">
                            <HashLink title="Shift Management" to='/Features#ShiftManagement'>
                                <div className="seo_service_item">
                                    <div className="service-icon">
                                        <img src={require('../../img/seo/shift_management.png')} width="71" height="71" alt="Shift Management" /></div>
                                    <h4>Shift Management</h4>
                                    <p>Creating a shift and putting multiple individuals into that shift. Defining a default shift. Editing shift start and end. </p>
                                    <div class="read-more">
                                        {/* <NavLink title="Get Started Now" className="read-more_btn" to="/Features/shift-management">Read More...</NavLink> */}
                                        <HashLink to="/Features#ShiftManagement" className="read-more_btn" >Read More...</HashLink>
                                    </div>
                                </div>
                            </HashLink>
                        </div>
                        <div className="iitem">
                            <HashLink title="Role Management" to='/Features#RoleManagement'>
                                <div className="seo_service_item">
                                    <div className="service-icon">
                                        <img src={require('../../img/seo/employee_roll.png')} width="75" height="75" alt="Role Management"  /></div>
                                    <h4>Role Management</h4>
                                    <p>Creating a role as a set of features and bestowing that role to individuals. Editing feature access any that role.</p>
                                    <div class="read-more">
                                        {/* <NavLink title="Get Started Now" className="read-more_btn" to="/Features/role-management">Read More...</NavLink> */}
                                        <HashLink to="/Features#RoleManagement" className="read-more_btn" >Read More...</HashLink>
                                    </div>
                                </div>
                            </HashLink>
                        </div>
                        <div className="iitem">
                            <HashLink title="Feature Access Management" to='/Features#FeatureAccessManagement'>
                                <div className="seo_service_item">
                                    <div className="service-icon">
                                        <img src={require('../../img/seo/feature_access_management.png')} width="71" height="71" alt="Feature Access Management"  /></div>
                                    <h4>Feature Access Management</h4>
                                    <p> Providing access or revoking access for a particular feature, gives an individual flexibility beyond there role.</p>
                                    <div class="read-more">
                                        {/* <NavLink title="Get Started Now" className="read-more_btn" to="/Features/feature-access-management">Read More...</NavLink> */}
                                        <HashLink to="/Features#FeatureAccessManagement" className="read-more_btn" >Read More...</HashLink>
                                    </div>
                                </div>
                            </HashLink>
                        </div>
                        <div className="iitem">
                            <HashLink title="Leave Management" to='/Features#LeaveManagement'>
                                <div className="seo_service_item">
                                    <div className="service-icon">
                                        <img src={require('../../img/seo/leave_management.png')} width="83" height="82" alt="Leave Management"  /></div>
                                    <h4>Leave Management</h4>
                                    <p>Managing employee leave requests, approving and rejecting them. Showing a balance of leaves to admin and employee.</p>
                                    <div class="read-more">
                                        {/* <NavLink title="Get Started Now" className="read-more_btn" to="/Features/leave-management">Read More...</NavLink> */}
                                        <HashLink to="/Features#LeaveManagement" className="read-more_btn" >Read More...</HashLink>
                                    </div>
                                </div>
                            </HashLink>
                        </div>
                        <div className="iitem">
                            <HashLink title="Team Management" to='/Features#TeamManagement'>
                                <div className="seo_service_item">
                                    <div className="service-icon">
                                        <img src={require('../../img/seo/team_management.png')} width="73" height="73" alt="Team Management"  /></div>
                                    <h4>Team Management</h4>
                                    <p>Managing a team by defining a team, including and removing an individual from that team and accessing team status.</p>
                                    <div class="read-more">
                                        {/* <NavLink title="Get Started Now" className="read-more_btn" to="/Features/team-management">Read More...</NavLink> */}
                                        <HashLink to="/Features#TeamManagement" className="read-more_btn" >Read More...</HashLink>
                                    </div>
                                </div>
                            </HashLink>
                        </div>
                        <div className="iitem">
                            <HashLink title="Reports" to='/Features#ReportsManagement'>
                                <div className="seo_service_item">
                                    <div className="service-icon">
                                        <img src={require('../../img/seo/reports.png')} width="54" height="73"alt="Reports" /></div>
                                    <h4>Reports</h4>
                                    <p>Viewing a relevant set of reports and make an informed and perfect decisions using those reports.</p>
                                    <div class="read-more">
                                        {/* <NavLink title="Get Started Now" className="read-more_btn" to="/Features/reports">Read More...</NavLink> */}
                                        <HashLink to="/Features#ReportsManagement" className="read-more_btn" >Read More...</HashLink>
                                    </div>
                                </div>
                            </HashLink>
                        </div>
                    </Slider>

                    {/*<Fade bottom duration={500}>*/}
                    {/*<div className="col-lg-4 col-md-6">*/}
                    {/*<div className="seo_service_item">*/}
                    {/*<div className="service-icon"> <img src={require('../../img/seo/attendance_track.png')} alt="Image"/></div>*/}
                    {/*<a href=".#">*/}
                    {/*<h4>Attendance Tracking</h4>*/}
                    {/*</a>*/}
                    {/*<p>No more punch in-and-out cards! Track your employees log-in, log-out, leave status, payroll, hours spent and much more with best-in-class attendance management app.</p>*/}
                    {/*<a href=".#"><i className="arrow_right"></i></a>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</Fade>*/}
                    {/*<Fade bottom duration={700} >*/}
                    {/*<div className="col-lg-4 col-md-6">*/}
                    {/*<div className="seo_service_item">*/}
                    {/*<div className="service-icon"><img src={require('../../img/seo/advanced_reports.png')} alt="Image"/></div>*/}
                    {/*<a href=".#">*/}
                    {/*<h4>Advanced Reports</h4>*/}
                    {/*</a>*/}
                    {/*<p>Hassle free time sheets and reports to measure and analyze how the teams spend their time on each project, letting employees to increase their productivity.</p>*/}
                    {/*<a href=".#"><i className="arrow_right"></i></a>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</Fade>*/}
                    {/*<Fade bottom duration={1000}>*/}
                    {/*<div className="col-lg-4 col-md-6">*/}
                    {/*<div className="seo_service_item">*/}
                    {/*<div className="service-icon">  <img src={require('../../img/seo/employee_gps.png')} alt="Image"/></div>*/}
                    {/*<a href=".#">*/}
                    {/*<h4>Employee GPS Time Tracking</h4>*/}
                    {/*</a>*/}
                    {/*<p>Monitor employee attendance working off-site and track live location status using the geofencing location tracking feature.</p>*/}
                    {/*<a href=".#"><i className="arrow_right"></i></a>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</Fade>*/}
                    {/*<div className="col-lg-12 text-center mt_40">*/}
                    {/*<a href=".#" className="seo_btn seo_btn_one btn_hover">All Features</a>*/}
                    {/*</div>*/}

                </div>
            </section>

            <section className="seo_features_mob_app sec_pad pb-0">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-5 col-md-12">
                            <div className="seo_features_img seo_features_img_two flex align-items-center">
                                {/*<div className="round_circle"></div>*/}
                                {/*<div className="round_circle two"></div>*/}
                                <img src={require('../../img/innerpage/engage Pages image/Landing pages Images/Group 75777.png')} width="470" height="482" alt="Mobile Application" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 d-flex align-items-center pr-5">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2 className="mob_app fnt18">Mobile <br /> Application</h2>
                                    <p>A perfert companion for the people who work on the move. Use Engage on the go, an easy and convenient way to clock-in and clock-out on the move. With the GPS and location tagging always know the location of the person clocking-in or out, An easy way for the employees to get the information on via calendar, also ask for permissions at the tap of a button.</p>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            <section className="seo_features_kiosk sec_pad pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="seo_features_img seo_features_img_two flex align-items-center">
                                {/*<div className="round_circle"></div>*/}
                                {/*<div className="round_circle two"></div>*/}
                                <img src={require('../../img//innerpage/engage Pages image/Landing pages Images/Group 75778.png')}  alt="Kiosk" height="430" width="470"/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 d-flex align-items-center pl-5">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2 className="fnt18">Kiosk</h2>
                                    <p>One device of all, now use engage in the form of a Kiosk. Fast and secure way to clock in and out with just one device. The perfect software for the daily needs of the many. With the security a key concept in the development makes the attendance process more accurate and reliable.</p>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            <section className="seo_features_one sec_pad pb-0">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-5 col-md-12">
                            <div className="seo_features_img">
                                {/*<div className="round_circle"></div>*/}
                                {/*<div className="round_circle two"></div>*/}
                                <img src={require('../../img/seo/all_feature.webp')}  alt="All Features" height="616" width="623"/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 pr-5">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2 className="fact_based fnt18">Make fact-based decisions based on approved and up-to-date information!</h2>
                                    <p>Analyze your company’s efficiency across projects while improving time management skills with our easy-to-use tools.</p>
                                    <div className="media seo_features_item pt-4">
                                        <div className="icon"><img src={require('../../img/seo/project_profitability.png')} width="31" height="31" alt="Profitability"  /></div>
                                        <div className="media-body">
                                            <h3>Profitability</h3>
                                            <p>Increase project profitability by analyzing real-time and accurate progress of each project and productivity of your team.</p>
                                        </div>
                                    </div>
                                    <div className="media seo_features_item">
                                        <div className="icon two"><img src={require('../../img/seo/business_efficiency.png')} width="39" height="32" alt="Business Efficiency"  /></div>
                                        <div className="media-body">
                                            <h3>Business Efficiency</h3>
                                            <p>Organizations can now manage their teams’ performances and overall employee workflow, using this effective employee time tracking app</p>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            <section className="seo_features_one sec_pad seo">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="seo_features_img seo_features_img_two flex align-items-center">
                                {/*<div className="round_circle"></div>*/}
                                {/*<div className="round_circle two"></div>*/}
                                <img src={require('../../img/seo/all_feature2.webp')} width="470" height="344" alt="Engage App" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 d-flex align-items-center pl-5">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2 className="wow fadeInUp fnt18">Join millions who track their team’s efforts efficiently with ‘Engage App’</h2>
                                    <h6 className="wow fadeInUp">Employee-friendly time tracking and productivity insights that enable teams to do their best work</h6>
                                    <p className="wow fadeInUp">This work management tool works best on both desktop and mobile devices, making the managers track real-time attendance and progress data, making employee’s workflow easy to track</p>
                                    {/*<a href=".#" className="seo_btn seo_btn_one btn_hover wow fadeInUp">Learn More</a>*/}
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            <section className="seo_call_to_action_area sec_pad mt_70">
                <div className="container">
                    <div className="seo_call_action_text">
                        <h2>Employee productivity and time tracking <br /> solution for all teams and industries</h2>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Service;
