import React, { Component } from 'react';
import Fade from "react-reveal/Fade";
import keyone from "../../img/time/key-1.webp";
import keytwo from "../../img/time/key-2.webp";
import keythree from "../../img/time/key-3.webp";
class Opportunities extends Component {
    render() {
        return (
            <div className="row justify-content-center pb-0" id="features">
                {/*<SeoTitle Title='Track productivity, attendance, and all your team’s efforts on a single platform!' TitleP='Plan and organize projects in a way that’s best suitable for your team, empowering them to self-manage their tasks and time.' />*/}
                <div className="col-md-6 feature-card">
                    <Fade bottom cascade>
                        <div className="iitem p-0">
                            <div className="seo_service_item sap-card p-0">
                                <div className="service-icon">
                                    <img className="w-100" src={keyone} width="680" height="200" alt="Employee Management" />
                                </div>
                                <div className="card-content oppor p-4">
                                    <h4 className="mt-0 font-20 font-weight-bold text-left position-relative">Here's how Engage helps you achieve more:</h4>
                                    <ul className="f_size_13 pl_20 mb-0">
                                        <li>Effortless Communication: Foster clear and timely communication through a central hub for announcements,requests, and approvals. Say goodbye to email clutter and missed messages.</li>
                                        <li>Enhanced Performance Management: Track key metrics, gain valuable employee insights, and identify areas for development. Implement effective incentive programs to motivate and reward your team.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className="col-md-6 feature-card">
                    <Fade bottom cascade>
                        <div className="iitem p-0">
                            <div className="seo_service_item sap-card p-0">
                                <div className="service-icon">
                                    <img className="w-100" src={keytwo} width="680" height="200" alt="Employee Management" />
                                </div>
                                <div className="card-content oppor p-4">
                                    <h4 className="mt-0 font-20 font-weight-bold text-left position-relative">Streamline Operations and Simplify HR</h4>
                                    <ul className="f_size_13 pl_20 mb-0">
                                        <li>Optimized Scheduling & Attendance: Create and customize daily, weekly, or monthly schedules with ease. Assign employees based on skillsets and ensure operational continuity with pre-designed templates. Track absences, clock-in/out times, and overtime for accurate payroll and performance evaluations.</li>
                                        <li>Location Management for Security & Visibility: Gain valuable insights into workforce management and security with location-based clock-in features. Receive notifications for entries outside designated work areas, allowing you to address potential security concerns or confirm legitimate remote work arrangements. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className="col-md-6 feature-card">
                    <Fade bottom cascade>
                        <div className="iitem p-0">
                            <div className="seo_service_item sap-card p-0">
                                <div className="service-icon">
                                    <img className="w-100" src={keythree} width="680" height="200" alt="Employee Management" />
                                </div>
                                <div className="card-content p-4">
                                    <h4 className="mt-0 font-20 font-weight-bold text-left position-relative">Ultimately, Engage helps you to:</h4>
                                    <ul className="f_size_13 pl_20 mb-0">
                                        <li>Improve workflow efficiency and reduce administrative burden.</li>
                                        <li>Empower your employees and foster a positive work environment.</li>
                                        <li>Gain data-driven insights for informed decision-making.</li>
                                        <li>Focus on what matters most - growing your business.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                {/*<div className="body_wrapper">*/}
                {/*<h1 className='py-5 mt-5'>Time and attendance management system</h1>*/}
                {/*/!*<Card style={{ width: '18rem' }}>*!/*/}
                {/*/!*<Card.Img variant="top" src="holder.js/100px180" />*!/*/}
                {/*/!*<Card.Body>*!/*/}
                {/*/!*<Card.Title>Card Title</Card.Title>*!/*/}
                {/*/!*<Card.Text>*!/*/}
                {/*/!*Some quick example text to build on the card title and make up the*!/*/}
                {/*/!*bulk of the card's content.*!/*/}
                {/*/!*</Card.Text>*!/*/}
                {/*/!*<Button variant="primary">Go somewhere</Button>*!/*/}
                {/*/!*</Card.Body>*!/*/}
                {/*/!*</Card>*!/*/}
                {/*</div>*/}
            </div>
        )
    }
};

export default Opportunities;