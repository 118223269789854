import React from "react";
import {useState} from "react"
import axios from "axios"
import * as z from "zod"
import {useForm} from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
const RequestDemoModal = () => {
  const [loading,setLoading]=useState(false)
  const [messageStatus,setMessageStatus]=useState("")
  const [showModal, setShowModal] = useState(false);
  const [success,setSuccess]=useState(false)
  
  const formSchedule = z.object({
    name: z
        .string({ required_error: 'Name is required!' })
        .min(3, { message: 'Name must be at least 3 characters.' })
        .max(30, { message: 'Name cannot contain more then 30 characters.' })
        .refine((value) => /^[a-zA-Z ]+$/.test(value), {
            message: 'Only alphabets allowed.',
        }),

        workEmail: z.string({ required_error: "Email is required!" })
  .min(1, "Email is required!").email("Please enter valid work email!")
  .regex(/^(?!.*@(?:gmail|yahoo)\.com).*$/, { message: "Please enter valid work email!" }),

     companyName: z
     .string({ required_error: 'Company name is required!' })
     .min(3, { message: 'Company name must be at least 3 characters.' })
     .max(30, { message: 'Company name cannot contain more then 30 characters.' })
     .refine((value) => /^[a-zA-Z ]+$/.test(value), {
         message: 'Only alphabets allowed.',
     }),
    industry: z.string({ required_error: "Please select Option.", }),
    conditions: z.boolean().refine(value => value === true, {
      message: "Please accept the Terms & Condition."
    }),
    hearAboutUs: z.string({ required_error: "Please select Option.", }),
    requirementMessage: z.string({ required_error: "Please select Option.", }),
    country: z.string({ required_error: "Please select Option.", }),
    mobile: z.string({ required_error: "Phone number required!", }).min(10, { message: 'Phone Number should contain 10 digit' }).max(13, { message: 'Phone Number should contain 10 digit' }),
})

const {register,handleSubmit,reset,formState:{errors,isSubmitting}}= useForm({ 
  resolver: zodResolver(formSchedule),
  mode: 'onChange',
})

  const onSubmit =async(values) => {
    setLoading(true)
    const data= new FormData()
    
    data.append("name",values?.name)
    data.append("company_name",values?.companyName)
    data.append("industry",values?.industry)
    data.append("work_email",values?.workEmail)
    data.append("phone",values?.mobile)
    data.append("country",values?.country)
    data.append("hear_about_us",values?.hearAboutUs)
    data.append("requirements",values?.requirementMessage)

    try {
      const response = await axios.post("https://api.engageapp.cloud/api/form-submit", data);
      setLoading(false);
      setMessageStatus(response?.data?.message || "Something went wrong!");
      setShowModal(true);
      setSuccess(true)
      reset()
    } catch (error) {
      console.error('Error occurred while submitting data:', error);
      setLoading(false);
      setMessageStatus("Something went wrong!");
      setShowModal(true);
      reset()
      setSuccess(false)
    }
    
  }

  return (
    <>
    {showModal ?<div className="modal">
   <div className="modal-dialog modal-dialog-centered modal-sm">
     <div className="modal-content border-0 rounded-10">
       <div className="modal-header pb-0 border-0 pt-4">
         <h5 style={{ textAlign: "center", width: "100%" }} className="modal-title font-weight-semibold text-dark-black font-18">
          {success ?
            <span style={{ display: "inline-block", marginRight: "5px" ,color: "green"}}>✅Success</span>:
                  <span style={{ display: "inline-block", marginRight: "5px" }}>
                    <span style={{ backgroundColor: "red", color: "white", padding: "3px 8px", borderRadius: "3px" }}>&times;</span> Failure
                  </span> }</h5>
         <button type="button" onClick={() => {
          setShowModal(false)
          setSuccess(false)
         }} className="close" data-dismiss="modal">
           <span>&times;</span>
         </button>
       </div>
       <div className="modal-body">
         <div className="card border-0 rounded-10 bg-midtheme">
           <div className="card-body p-4" >
           <h5 className="modal-title font-weight-semibold text-dark-black font-18">
           <span style={{ color: "black", textAlign: "center", width: "100%" }}>{messageStatus}</span>
         </h5>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>:
    <div className="modal book-demo fade" id="getDemoBtp">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-10">
          <div className="modal-header pb-0 border-0 pt-4">
            <h5 className="modal-title font-weight-semibold text-dark-black font-18">
              Schedule Your Free Demo
            </h5>
            <button type="button" className="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="card border-0 rounded-10 bg-midtheme">
              <div className="card-body p-4">
                <form onSubmit={handleSubmit(onSubmit)} id="ScheduleDemoForm">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="form-group">
                        <label
                          for="name"
                          className="font-15 text-dark-black"
                        >
                          Name&nbsp;<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          className="form-control border-0 text-black  px-3 font-12"
                          id="name"
                          placeholder="Enter your full name"
                          {...register("name")}
                        />
                        {errors.name && <div style={{color:"red",fontSize:10}}>{errors.name.message}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label
                          for="companyName"
                          className="font-15 text-dark-black"
                        >
                          Company name &nbsp;<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="companyName"
                          className="form-control border-0 text-black px-3  font-12"
                          id="companyName"
                          placeholder="Enter your company name"
                          {...register("companyName")}
                        />
                         {errors.companyName && <div style={{color:"red",fontSize:10}} className="text-red-500">{errors.companyName.message}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label
                          for="industry"
                          className="font-15 text-dark-black"
                        >
                          Industry
                        </label>
                        <input
                          type="text"
                          name="industry"
                          className="form-control border-0 text-black px-3 font-12"
                          id="industry"
                          placeholder="Enter your industry name"
                          {...register("industry")}
                        />
                        {errors.industry && <div style={{color:"red",fontSize:10}} className="text-red-500">{errors.industry.message}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label
                          for="workEmail"
                          className="font-15 text-dark-black"
                        >
                          Work email&nbsp;<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="workEmail"
                          className="form-control border-0 text-black px-3 font-12"
                          id="workEmail"
                          placeholder="Enter your email"
                          {...register("workEmail")}
                        />
                        {errors.workEmail && <div style={{color:"red",fontSize:10}} className="text-red-500">{errors.workEmail.message}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label className="font-15 text-dark-black" for="mobile">
                          Mobile&nbsp;<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="mobile"
                          id="mobile"
                          className="form-control border-0 text-black px-3 font-12"
                          placeholder="Enter your mobile number"
                          {...register("mobile")}
                        />
                         {errors.mobile && <div style={{color:"red",fontSize:10}} className="text-red-500">{errors.mobile.message}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group selectdiv">
                        <label
                          for="country"
                          className="font-15 text-dark-black"
                        >
                          Country
                        </label>
                        <div className="select-wrapper">
                          <select
                            name="country"
                            className="form-control px-3 selection"
                            id="country"
                            {...register("country")}
                          >
                              {errors.country && <div style={{color:"red",fontSize:10}} className="text-red-500">{errors.country.message}</div>}
                            <option selected disabled>
                              Please select
                            </option>
                            <option value="UAE"> UAE </option>
                            <option value="India"> India </option>
                            <option value="Austrlia"> Austrlia </option>
                            <option value="London"> London </option>
                            <option value="USA"> USA </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          for="hearAboutUs"
                          className="font-15 text-dark-black"
                        >
                          How did you hear about us
                        </label>
                        <div className="select-wrapper">
                          <select
                            name="hearAboutUs"
                            className="form-control px-3 selection"
                            id="hearAboutUs"
                            {...register("hearAboutUs")}
                          >
                            <option selected disabled>
                              Please select
                            </option>
                            <option value="Social Media">Social Media</option>
                            <option value="Friends"> Friends </option>
                            <option value="Family"> Family </option>
                            <option value="Others"> Others </option>
                          </select>
                          {errors.hearAboutUs && <div style={{color:"red",fontSize:10}} className="text-red-500">{errors.hearAboutUs.message}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          for="requirementMessage"
                          className="font-15 text-dark-black"
                        >
                          Tell us about your requirements
                        </label>
                        <textarea
                          className="form-control font-12 border-0 text-black"
                          id="requirementMessage"
                          name="requirementMessage"
                          rows="4"
                          placeholder="Write your message here..."
                          {...register("requirementMessage")}
                        >
                          {errors.requirementMessage && <div style={{color:"red",fontSize:10}} className="text-red-500">{errors.requirementMessage.message}</div>}
                        </textarea>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input conditions"
                          id="conditions"
                          {...register("conditions")}
                          // onClick={()=>setCheckTermCondition(!checkTermCondition)}
                        />
                        <label
                          className="custom-control-label font-weight-normal font-12"
                          for="conditions"
                        >
                          I agree to receive other communication from Engage
                        </label>
                        {errors.conditions && <div style={{color:"red",fontSize:10}} className="text-red-500">{errors.conditions.message}</div>}
                      </div>
                    </div>
                    <div className="col-12 pt-3">
                      <h6 className="font-12 lh-18 text-dark-black font-weight-normal">
                        We're committed to your privacy. RealCube uses the
                        information you provide to us to contact you about
                        relevant content, products and services. You may
                        unsubscribe from these communications at any time.
                      </h6>
                      {/* {messageStatus} */}
                    </div>
                    <div className="col-md-12 col-12 pt-4 text-right">
                      <button
                        className="btn_three mt-0"
                        id="form_submit"
                        // data-dismiss="modal"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ?"Loading......": "Schedule free demo!"}
                        {/* Schedule free demo! */}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>}
    </>
  );
};

export default RequestDemoModal;
