import React, {Component} from 'react';


const GetInTouch = () => {
    return (
        <React.Fragment>
            <section className="get_touch" id="contact">
                <div className="container">
                    <div className="max-1000">
                        <h2 className="fnt-45">Get In Touch with Us!</h2>
                        <form>
                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <input type="text" className="form-control" placeholder="Full Name" name="fname" required />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <input type="text" className="form-control" placeholder="Email Id" name="email" required />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <input type="text" className="form-control" placeholder="Mobile Number" name="phone"  required />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <input type="text" className="form-control" placeholder="Company Name" name="company" required />
                                </div>
                                <div className="col submit-btn">
                                    <button data-toggle="modal" data-target="#get-in-touch-success-popup" type="submit" name="submit" className="btn_three"> Submit </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <div className="modal fade get-in-touch-success-popup" id="get-in-touch-success-popup">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0 rounded">
                        <div className="modal-header pb-4 border-bottom-0 text-right">
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <img src={require ("../img/completed-tick.svg")} width="45px" alt="image" className="img-fluid mb-3" />
                            <h4 className="text-center"> Thank you! </h4>
                            <label id="thank" className="text-center d-block">Your request has been successfully recorded . <br/> We will get in touch with you shortly.</label>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default GetInTouch;
