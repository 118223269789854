import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Reveal from 'react-reveal/Reveal'
class Footer extends Component {
    render() {
        let FooterData = this.props.FooterData;
        return (
            <footer className="new_footer_area bg_color">

                <div className="footer_bottom">
                    <div className="container">
                        <div className="row align-items-center ">
                            <div className="col-lg-6 col-md-12">
                                <p className="mb-0 f_400 txt-cnt left_copy">{FooterData.copywrite}</p>
                            </div>
                            <div className="col-lg-3 col-md-12 p-0 txt-cnt">
                                {window.location.pathname != '/' ? <nav >
                                    <NavLink title="Get More" className="btn_get_more btn_hover new_btn mt-0 mt-md-0" to="/schedule-demo">Get Started More</NavLink>
                                </nav> : null}
                            </div>
                            <div className="col-lg-3 col-md-12 text-right txt-cnt right_soc">
                                {/*<p>Made with <i className="icon_heart"></i> in <a href="/#">DroitThemes</a></p>*/}
                                {
                                    FooterData.SocialLinks.map(widget => {
                                        return (
                                            <Reveal effect="fadeInLeft" duration={500} key={widget.id}>

                                                <div className="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s">
                                                    {/*<h3 className="f-title f_600 t_color f_size_18">{widget.title}</h3>*/}
                                                    <div className="f_social_icon">
                                                        {
                                                            widget.menuItems.map(item => {
                                                                return (
                                                                    <a href={item.url} target='_blank'>
                                                                        <i className={item.icon}></i></a>
                                                                    // <Link to={item.url} key={item.id}><i className={item.icon}></i></Link>

                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>

                                            </Reveal>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default Footer;