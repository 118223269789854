import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import EducationPage from "../components/Innerpages/EducationComponent";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import EmployeeManagement from "./EmployeeManagement";
import LocationManagement from "./LocationManagement";
import ShiftManagement from "./ShiftManagement";
import RoleManagement from "./RoleManagement";
import FeatureAccessManagement from "./FeatureAccessManagement";
import TeamManagement from "./TeamManagement";
import LeaveManagement from "./LeaveManagement";
import ReportsManagement from "./ReportsManagement";
import MenuNavigation from "../components/MenuNavigation/MenuNavigation";
import { Helmet } from "react-helmet";

const Education = () => {
    return (
        <>
            <Helmet>
                <title>Features of Employee Management System - Engage App</title>
                <meta
                    name="description"
                    content="Use single platform to manage your employees and team activities with shift and location management features for smooth and effective work flow."
                />
                <meta
                    name="author"
                    content="Engage"
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Features of Employee Management System - Engage App" />
                <meta property="og:description" content="Use single platform to manage your employees and team activities with shift and location management features for smooth and effective work flow." />
                <meta property="og:url" content="https://www.engageapp.cloud/" />

                <meta property="og:site_name" content="Engage" />
                <meta property="og:image" content="" />
                <meta property="og:image:width" content="200" />
                <meta property="og:image:height" content="200" />
                <meta property="og:image:alt" content="Features of Employee Management System - Engage App" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Use single platform to manage your employees and team activities with shift and location management features for smooth and effective work flow." />
                <meta name="twitter:title" content="Features of Employee Management System - Engage App" />
                <meta name="twitter:site" content="https://www.engageapp.cloud/" />
                <meta name="twitter:creator" content="@engage" />
            </Helmet>
            <div className="body_wrapper">
                <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn" />
                <div style={{ height: 120 }} > <MenuNavigation /></div>
                {/*<div className="mt-5">*/}
                       <div className="container pt-5 mt-3 ">
                           <div className="position-relative features_engage">
                               <h1 className="text-center text-dark font-weight-bold"><span className="position-relative d-inline-block px-lg-5 px-md-4 px-4 py-2 py-lg-3 pl-md-3">Engage Features</span></h1>
                           </div>
                       </div>

                    <div id="EmployeeManagement" className="pt-130">
                        <EmployeeManagement />
                    </div>
                    <div id="LocationManagement" className="pt-130">
                        <LocationManagement />
                    </div>
                    <div id="ShiftManagement" className="pt-130">
                        <ShiftManagement />
                    </div>
                    <div id="RoleManagement" className="pt-130">
                        <RoleManagement />
                    </div>
                    <div id="FeatureAccessManagement" className="pt-130">
                        <FeatureAccessManagement />
                    </div>
                    <div id="TeamManagement" className="pt-130">
                        <TeamManagement />
                    </div>
                    <div id="LeaveManagement" className="pt-130">
                        <LeaveManagement />
                    </div>
                    <div id="ReportsManagement" className="pt-130 mb-5">
                        <ReportsManagement />
                    </div>
                {/*</div>*/}
                <Footer FooterData={FooterData} />
            </div>
        </>
    );
};
export default Education;
