import React, { Component } from 'react';
import Fade from "react-reveal/Fade";
import keyfour from "../../img/time/key-4.webp";
import keyfive from "../../img/time/key-5.webp";
import keysix from "../../img/time/key-6.webp";
class Opportunities extends Component {
    render() {
        return (
            <div className="row justify-content-center pb-0" id="features">
                {/*<SeoTitle Title='Track productivity, attendance, and all your team’s efforts on a single platform!' TitleP='Plan and organize projects in a way that’s best suitable for your team, empowering them to self-manage their tasks and time.' />*/}
                <div className="col-md-6 feature-card">
                    <Fade bottom cascade>
                        <div className="iitem p-0">
                            <div className="seo_service_item sap-card p-0">
                                <div className="service-icon">
                                    <img className="w-100" src={keysix} width="680" height="200" alt="Employee Management" />
                                </div>
                                <div className="card-content opportwo p-4">
                                    <h4 className="mt-0 font-20 font-weight-bold text-left position-relative">Role Management: Empower Teams with the Right Access</h4>
                                    <ul className="f_size_13 pl_20 mb-0">
                                        <li>Establish Clear Hierarchies: Define a clear structure of user permissions based on specific job roles.</li>
                                        <li>Enhanced Security: Restrict access to sensitive features and data based on designated roles,minimizing risks of unauthorized access.</li>
                                        <li>Transparency & Trust: Promote transparency within your team by providing visibility into individual access levels.</li>
                                        <li>Streamlined Collaboration: Ensure everyone has the necessary permissions to complete their tasks, fostering efficient collaboration</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className="col-md-6 feature-card">
                    <Fade bottom cascade>
                        <div className="iitem p-0">
                            <div className="seo_service_item sap-card p-0">
                                <div className="service-icon">
                                    <img className="w-100" src={keyfour} width="680" height="200" alt="Employee Management" />
                                </div>
                                <div className="card-content opportwo p-4">
                                    <h4 className="mt-0 font-20 font-weight-bold text-left position-relative">Feature Access Management: Go Beyond Roles for Enhanced Flexibility</h4>
                                    <ul className="f_size_13 pl_20 mb-0">
                                        <li>Granular Control: Empower authorized personnel to grant temporary access to specific features outside of a user's designated role.</li>
                                        <li>Invaluable During Emergencies: This functionality ensures tasks stay on track during unexpected absences or critical situations.</li>
                                        <li>Planned Leave? No Problem: Grant temporary access to colleagues, ensuring continuity and minimizing disruptions when team members are away.</li>
                                        <li>Unforeseen Challenges? Covered: Address urgent situations by granting access to necessary features, keeping your workflow efficient.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className="col-md-6 feature-card">
                    <Fade bottom cascade>
                        <div className="iitem p-0">
                            <div className="seo_service_item sap-card p-0">
                                <div className="service-icon">
                                    <img className="w-100" src={keyfive} width="680" height="200" alt="Employee Management" />
                                </div>
                                <div className="card-content p-4">
                                    <h4 className="mt-0 font-20 font-weight-bold text-left position-relative">Benefits of Our Access Control System:</h4>
                                    <ul className="f_size_13 pl_20 mb-0">
                                        <li>Enhanced Security: Minimize data breaches and ensure only authorized users have access to critical      information.</li>
                                        <li>Improved Efficiency: Reduce time spent managing individual user permissions.</li>
                                        <li>Empowered Teams: Grant your team members the access they need to thrive.</li>
                                        <li>Scalability & Flexibility: Adapts to your growing team and evolving needs.</li>
                                        <li>Ready to streamline user management and empower your workforce?</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                {/*<div className="body_wrapper">*/}
                {/*<h1 className='py-5 mt-5'>Time and attendance management system</h1>*/}
                {/*/!*<Card style={{ width: '18rem' }}>*!/*/}
                {/*/!*<Card.Img variant="top" src="holder.js/100px180" />*!/*/}
                {/*/!*<Card.Body>*!/*/}
                {/*/!*<Card.Title>Card Title</Card.Title>*!/*/}
                {/*/!*<Card.Text>*!/*/}
                {/*/!*Some quick example text to build on the card title and make up the*!/*/}
                {/*/!*bulk of the card's content.*!/*/}
                {/*/!*</Card.Text>*!/*/}
                {/*/!*<Button variant="primary">Go somewhere</Button>*!/*/}
                {/*/!*</Card.Body>*!/*/}
                {/*/!*</Card>*!/*/}
                {/*</div>*/}
            </div>
        )
    }
};

export default Opportunities;