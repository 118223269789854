import React from "react";
import Fade from "react-reveal/Fade";
import empimg from "../../img/time/emp-mgmt.webp";
import { Link } from "react-router-dom";
const Employee = () => {
  return (
    <div className="body_wrapper">
      <section className="experience-section py-md-4 py-lg-5 py-3">
        <div className="container pb-sm-10">
          <div className="row mb-2">
            <div className="col-md-7">
              <div className="sec-one">
                <h2 className="exp-main-head font-weight-bold mb-2">Employee Management</h2>
                <h4 className="exp-sub-head mb-2">Empower Your Workforce with Streamlined Employee Management</h4>
                <p className="exp-sub-para font-weight-normal">Empower your workforce with a streamlined employee management system. This system fosters clear communication through a central hub, ensuring timely review and approval of requests to minimize workflow delays. It also enhances performance management by tracking key metrics and offering valuable insights to guide development and implement effective incentive programs. Additionally, the system optimizes scheduling and attendance by monitoring absences, clock-in/out times, and overtime hours, ensuring fair and accurate tracking for payroll and performance evaluations. Ultimately, this comprehensive approach improves workflow, enhances performance, and simplifies administrative tasks.</p>
              </div>
              <div className="sec-two">
                <h2 className="exp-main-head font-weight-bold mb-2">Location Management</h2>
                <h4 className="exp-sub-head mb-2">Enhance Security and Visibility with Location Management</h4>
                <p className="exp-sub-para font-weight-normal">Knowing where employees clock in from provides valuable insights for security and workforce management. This feature automatically pinpoints employee locations upon clock-in, notifying administrators if entries occur outside designated work areas. This allows managers to investigate potential security concerns or simply confirm legitimate remote work arrangements.</p>
              </div>
              <div className="sec-three">
                <h2 className="exp-main-head font-weight-bold mb-2">Shift Management</h2>
                <h4 className="exp-sub-head mb-2">Effortless Shift Scheduling and Management:</h4>
                <ul className="exp-sub-para font-weight-normal pl_20">
                  <li className="">Create and customize daily, weekly, or monthly schedules with ease.</li>
                  <li>Assign employees to specific shifts based on task requirements and skill sets.</li>
                  <li>Ensure operational continuity with pre-designed default shifts for unexpected absences.</li>
                  <li>Maintain flexibility by allowing adjustments to shift start and end times as needed.</li>
                </ul>
              </div>
              <Link to="/employee-shift-and-location-management-software"
                className="btn_three mt-2 mb-3 mb-md-0 mb-lg-0"
              >
                Read More
              </Link>
            </div>
            <div className="col-md-5">
              <img className="img-fluid" src={empimg} width="" height="" alt="Employee Management" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Employee;
