import React from 'react';
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import CustomNavbar from '../components/CustomNavbar';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';
import Banner from '../components/TimeManagementSystem/Banner';
import Excellence from '../components/TimeManagementSystem/Excellence';
import Employee from '../components/TimeManagementSystem/Employee';
import Role from "../components/TimeManagementSystem/Role";
import Team from "../components/TimeManagementSystem/Team";
import Subscribe from '../components/Subscribe';
import GetInTouch from '../components/Features';

const TimeManagementSystem = () => {

    return(
        <>
            <Helmet>
                <title>Engage App Cloud - Time and attendance management system</title>
                <meta
                    name="description"
                    content="Engage is a simple and efficient employee management tool, it helps you to plan and organize in a way which is best suitable for you and your team."
                />
                <meta
                    name="author"
                    content="Engage"
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Engage App Cloud - Time and attendance management system" />
                <meta property="og:description" content="Engage is a simple and efficient employee management tool, it helps you to plan and organize in a way which is best suitable for you and your team." />
                <meta property="og:url" content="https://www.engageapp.cloud/" />

                <meta property="og:site_name" content="Engage" />
                <meta property="og:image" content="" />
                <meta property="og:image:width" content="200" />
                <meta property="og:image:height" content="200" />
                <meta property="og:image:alt" content="Engage App Cloud - Time and attendance management system" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Engage is a simple and efficient employee management tool, it helps you to plan and organize in a way which is best suitable for you and your team." />
                <meta name="twitter:title" content="Engage App Cloud - Time and attendance management system" />
                <meta name="twitter:site" content="https://www.engageapp.cloud/" />
                <meta name="twitter:creator" content="@engage" />
            </Helmet>
            <div className="body_wrapper sap-text">
                <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>
                <Banner/>
                <Employee/>
                <Role/>
                <Team/>
                <Excellence/>
                <Subscribe/>
                <GetInTouch/>
                <Footer FooterData={FooterData}/>
            </div>
        </>
    )
};

export default TimeManagementSystem;