import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Sservice from '../components/Service/Sservice/Sservice';
import AgencyAbout from '../components/About/AgencyAbout';
import Partner from '../components/Partner';
import Footer from '../components/Footer/Footer';
import ServiceData from '../components/Service/ServiceData';
import FooterData from '../components/Footer/FooterData';
import AboutBanner from './AboutBanner'
import { Helmet } from "react-helmet";
import Fade from 'react-reveal/Fade';

const Certification = () => {

    return(
        <>
            <Helmet>
                <title>Engage App Cloud - Certification</title>
                <meta
                    name="description"
                    content="Engage is a simple and efficient employee management tool, it helps you to plan and organize in a way which is best suitable for you and your team."
                />
                <meta
                    name="author"
                    content="Engage"
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Engage App Cloud - About Us" />
                <meta property="og:description" content="Engage is a simple and efficient employee management tool, it helps you to plan and organize in a way which is best suitable for you and your team." />
                <meta property="og:url" content="https://www.engageapp.cloud/" />

                <meta property="og:site_name" content="Engage" />
                <meta property="og:image" content="" />
                <meta property="og:image:width" content="200" />
                <meta property="og:image:height" content="200" />
                <meta property="og:image:alt" content="Engage App Cloud - About Us" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Engage is a simple and efficient employee management tool, it helps you to plan and organize in a way which is best suitable for you and your team." />
                <meta name="twitter:title" content="Engage App Cloud - About Us" />
                <meta name="twitter:site" content="https://www.engageapp.cloud/" />
                <meta name="twitter:creator" content="@engage" />
            </Helmet>
            <div className="body_wrapper certification">
                <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>
                {/*<Breadcrumb breadcrumbClass="breadcrumb_area" imgName="AboutUs/About_Us_banner.webp" Ptitle="About Us" Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"/>*/}
                <section className="seo_features_about certify-banner certify-pad pt-5 mt-5 px-3 px-md-0 about-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 banner rounded-10 overflow-hidden">
                                <div className="row">
                                    <div className="col-md-6 d-flex align-items-center about-content pl-4 justify-content-center justify-content-md-left">
                                        <Fade bottom cascade>
                                            <div className="seo_features_content justify-content-start py-4 py-lg-0">
                                                <h1 className="text-white mb-3\
                                                ">Engage with your Workforce Better! </h1>
                                                <p className="text-white">Your go-to app for seamless employee management </p>
                                                <a href="/schedule-demo" className="btn_three mt-0 mt-md-3 mx-auto mx-md-0 d-table"> Book a Demo </a>
                                            </div>
                                        </Fade>
                                    </div>
                                    <div className="col-md-6 m-0">
                                        <div className="seo_features_img_two about-pic">
                                            <img src='https://exalogic-store.s3.us-east-2.amazonaws.com/engage/banner-right.webp' width="544" height="434" alt="Certification" className="about_picc mx-auto mx-md-0 d-table" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="certify-pad py-4 py-md-5 my-4 about-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 d-flex align-items-center about-content mb-3 mb-md-0">
                                <Fade bottom cascade>
                                    <div className="seo_features_content justify-content-start">
                                        <h1>Employee Management</h1>
                                        <p>Manage employee activities with transparency using our Employee Management feature. Review and approve employee permissions to minimize workflow lags. Track performance, grievances, and suggestions. Monitor absenteeism, clock-in/out times, and overtime for uniformity and incentives. Keep tabs on employee location during work hours for better oversight.</p>
                                        {/*<a href="/schedule-demo" className="btn_three mt-0 mt-md-3"> Book a Demo </a>*/}
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-md-6 m-0">
                                <div className="seo_features_img_two about-pic">
                                    <img src='https://exalogic-store.s3.us-east-2.amazonaws.com/engage/certify-emp.webp' width="551" height="395" alt="Certification" className="about_picc mx-auto mx-md-0 d-table" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="certify-pad py-4 py-md-5 my-4 about-us bg-blue">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mb-3 mb-md-0">
                                <div className="seo_features_img_two about-pic">
                                    <img src='https://exalogic-store.s3.us-east-2.amazonaws.com/engage/certify-shift.webp' width="551" height="395" alt="Certification" className="about_picc mx-auto mx-md-0 d-table" />
                                </div>
                            </div>
                            <div className="col-md-6 d-flex align-items-center about-content">
                                <Fade bottom cascade>
                                    <div className="seo_features_content justify-content-start text-right">
                                        <h1>Shift and Team Management</h1>
                                        <p>Efficiently create and manage shifts by customizing date and time slots, arranging tasks, and assigning individuals or groups. Design default shifts to ensure continuity during absenteeism or glitches. Team management allows leaders to define teams, manage members, and access team status, including online presence and future leaves, enabling proactive planning and oversight.</p>
                                        {/*<a href="/schedule-demo" className="btn_three mt-0 mt-md-3"> Book a Demo </a>*/}
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="certify-pad py-4 py-md-5 my-4 about-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 d-flex align-items-center about-content mb-3 mb-md-0">
                                <Fade bottom cascade>
                                    <div className="seo_features_content justify-content-start">
                                        <h1>Leave and Role Management</h1>
                                        <p>The Leave Management feature streamlines the leave application process, allowing employees to specify reasons and attach documents. Managers can easily review and approve/reject requests promptly. Role Management assigns access based on positions, enhancing security and clarity of delegated powers, thus improving internal collaboration and preventing unauthorized access.</p>
                                        {/*<a href="/schedule-demo" className="btn_three mt-0 mt-md-3"> Book a Demo </a>*/}
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-md-6">
                                <div className="seo_features_img_two about-pic">
                                    <img src='https://exalogic-store.s3.us-east-2.amazonaws.com/engage/certify-leave.webp' width="551" height="395" alt="Certification" className="about_picc mx-auto mx-md-0 d-table" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="certify-pad certify-video py-4 py-md-5 my-4 about-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="text-black text-center">Hear it from <span>HR professionals</span> like you!</h2>
                                <div className="video-sect">
                                    <video width="820" controls poster="https://exalogic-store.s3.us-east-2.amazonaws.com/engage/certify-video-poster.webp">
                                        <source src="https://exalogic-store.s3.us-east-2.amazonaws.com/engage/engage-certification.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="seo_features_about certify-about py-4 py-md-5 my-4 about-us text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <Fade bottom cascade>
                                    <div className="seo_features_content justify-content-start certify-width">
                                        <span className="text-uppercase font-weight-semibold">About Us</span>
                                        <h2 className="mb-3">Why Choose Engage App?</h2>
                                        <p className="mb-5">Engage App's major USP is IrisID, a highly secure biometric identification device for attendance and access control.</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 mb-3 mb-md-0">
                                            <div className="position-relative certify-abt overflow-hidden rounded-10">
                                                <img src='https://exalogic-store.s3.us-east-2.amazonaws.com/engage/certify-about-1.webp' width="369" height="346" alt="Certification" className="about_picc object-cover object-center" />
                                                <p className="m-0 position-absolute font-weight-semibold text-white text-left px-3 py-2 py-lg-3">Education</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 mb-md-0">
                                            <div className="position-relative certify-abt overflow-hidden rounded-10">
                                                <img src='https://exalogic-store.s3.us-east-2.amazonaws.com/engage/certify-about-2.webp' width="369" height="346" alt="Certification" className="about_picc object-cover object-center" />
                                                <p className="m-0 position-absolute font-weight-semibold text-white text-left px-3 py-2 py-lg-3">Healthcare</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 mb-md-0">
                                            <div className="position-relative certify-abt overflow-hidden rounded-10">
                                                <img src='https://exalogic-store.s3.us-east-2.amazonaws.com/engage/certify-about-3.webp' width="369" height="346" alt="Certification" className="about_picc object-cover object-center" />
                                                <p className="m-0 position-absolute font-weight-semibold text-white text-left px-3 py-2 py-lg-3">Hospitality</p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="seo_features_about certify-about certify-aim-section position-relative py-4 py-md-5 mt-4 about-us text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <Fade bottom cascade>
                                    <div className="seo_features_content justify-content-start certify-width">
                                        <h2 className="mb-3">Engage App aims to foster transparency </h2>
                                        <p className="mb-5 text-white ">and inclusivity within the workplace culture.</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 mb-3 mb-lg-0">
                                            <div className="position-relative overflow-hidden certify-aim text-left p-4 rounded-10">
                                                <img src='https://exalogic-store.s3.us-east-2.amazonaws.com/engage/peer.webp' width="87" height="71" alt="Certification" className="about_picc mb-4" />
                                                <h4 className="text-white text-left mb-4">Peer Recognition</h4>
                                                <p className="text-white text-left mb-0">Engage promotes peer recognition by enabling seamless collaboration and focusing on meaningful work, fostering a supportive and productive team environment.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-3 mb-lg-0">
                                            <div className="position-relative overflow-hidden certify-aim text-left p-4 rounded-10">
                                                <img src='https://exalogic-store.s3.us-east-2.amazonaws.com/engage/retention.webp' width="58" height="71" alt="Certification" className="about_picc mb-4" />
                                                <h4 className="text-white text-left mb-4">Increase Retention</h4>
                                                <p className="text-white text-left mb-0">It helps enhance retention by empowering teams with efficient organization, collaboration, and focus on meaningful work, fostering a positive and satisfying work experience.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-3 mb-lg-0">
                                            <div className="position-relative overflow-hidden certify-aim text-left p-4 rounded-10">
                                                <img src='https://exalogic-store.s3.us-east-2.amazonaws.com/engage/productivity.webp' width="64" height="64" alt="Certification" className="about_picc mb-4" />
                                                <h4 className="text-white text-left mb-4">Better Productivity</h4>
                                                <p className="text-white text-left mb-0">It boosts productivity by simplifying organization, fostering collaboration, and streamlining tasks, allowing teams to focus on impactful work and achieve higher efficiency.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section className="seo_features_about certify-about certify-insights-bg position-relative py-4 py-md-5 my-4 about-us text-center">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-lg-12">*/}
                                {/*<Fade bottom cascade>*/}
                                    {/*<div className="seo_features_content justify-content-start certify-width">*/}
                                        {/*<span className="text-uppercase font-weight-semibold">Blogs</span>*/}
                                        {/*<h2 className="mb-3">Our recent news & insights</h2>*/}
                                    {/*</div>*/}
                                    {/*<div className="row">*/}
                                        {/*<div className="col-lg-4 col-md-6 mb-3 mb-lg-0">*/}
                                            {/*<div className="position-relative certify-insights overflow-hidden bg-white shadow text-left p-1 rounded-10">*/}
                                                {/*<div className="border rounded-10 overflow-hidden">*/}
                                                    {/*<img src='https://exalogic-store.s3.us-east-2.amazonaws.com/engage/insight-1.webp' width="392" height="231" alt="Certification" className="about_picc mb-2" />*/}
                                                    {/*<div className="p-3">*/}
                                                        {/*<span className="font-12 px-3 py-0 rounded-pill mb-2 d-inline-block">Benefits</span>*/}
                                                        {/*<h6 className="text-left mb-2 font-18">Adding Benefits in Marketing Sectors</h6>*/}
                                                        {/*<p className="text-left font-14 mb-2">Kelly Manson - Aug 30, 2024</p>*/}
                                                        {/*<p className="text-left mb-0 font-14">Lorem ipsum dolor sit amet consectetur. Nullam blandit dui gravida al</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-lg-4 col-md-6 mb-3 mb-lg-0">*/}
                                            {/*<div className="position-relative certify-insights overflow-hidden bg-white shadow text-left p-1 rounded-10">*/}
                                                {/*<div className="border rounded-10 overflow-hidden">*/}
                                                    {/*<img src='https://exalogic-store.s3.us-east-2.amazonaws.com/engage/insight-2.webp' width="392" height="231" alt="Certification" className="about_picc mb-2" />*/}
                                                    {/*<div className="p-3">*/}
                                                        {/*<span className="font-12 px-3 py-0 rounded-pill mb-2 d-inline-block">Research</span>*/}
                                                        {/*<h6 className="text-left mb-2 font-18">Documents required for business analysis</h6>*/}
                                                        {/*<p className="text-left font-14 mb-2">Will Smith - Aug 30, 2024</p>*/}
                                                        {/*<p className="text-left mb-0 font-14">Lorem ipsum dolor sit amet consectetur. Nullam blandit dui gravida al</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-lg-4 col-md-6 mb-3 mb-lg-0">*/}
                                            {/*<div className="position-relative certify-insights overflow-hidden bg-white shadow text-left p-1 rounded-10">*/}
                                                {/*<div className="border rounded-10 overflow-hidden">*/}
                                                    {/*<img src='https://exalogic-store.s3.us-east-2.amazonaws.com/engage/insight-3.webp' width="392" height="231" alt="Certification" className="about_picc mb-2" />*/}
                                                    {/*<div className="p-3">*/}
                                                        {/*<span className="font-12 px-3 py-0 rounded-pill mb-2 d-inline-block">Payment</span>*/}
                                                        {/*<h6 className="text-left mb-2 font-18">Manage your online banking & get rewarded</h6>*/}
                                                        {/*<p className="text-left font-14 mb-2">Madison Watson - Aug 30, 2024</p>*/}
                                                        {/*<p className="text-left mb-0 font-14">Lorem ipsum dolor sit amet consectetur. Nullam blandit dui gravida al</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                {/*</Fade>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <section className="seo_features_about certify-pad demo-bg py-0 mt-0 about-us justify-content-center justify-content-md-left text-center text-md-left">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 d-block d-md-flex align-items-center about-content">
                                <Fade bottom cascade>
                                    <div className="seo_features_content justify-content-start py-3">
                                        <h1>Book Free Demo</h1>
                                        <p>Join millions optimizing team efficiency with the 'Engage App' for tracking efforts effectively.</p>
                                        <a href="/schedule-demo" className="btn_three mt-0 mt-md-3"> Free Demo </a>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-md-6">
                                <div className="seo_features_img_two about-pic">
                                    <img src='https://exalogic-store.s3.us-east-2.amazonaws.com/engage/demo.webp' width="600" height="552" alt="Certification" className="about_picc" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer FooterData={FooterData}/>
            </div>
        </>
    )
};

export default Certification;