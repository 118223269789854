import React, { Component } from 'react';
import Fade from "react-reveal/Fade";
import keyone from "../../img/time/key-1.webp";
import keytwo from "../../img/time/key-2.webp";
import keythree from "../../img/time/key-3.webp";
class Opportunities extends Component {
    render() {
        return (
            <div className="row justify-content-center pb-0" id="features">
                {/*<SeoTitle Title='Track productivity, attendance, and all your team’s efforts on a single platform!' TitleP='Plan and organize projects in a way that’s best suitable for your team, empowering them to self-manage their tasks and time.' />*/}
                <div className="col-md-6 feature-card">
                    <Fade bottom cascade>
                        <div className="iitem p-0">
                            <div className="seo_service_item sap-card p-0">
                                <div className="service-icon">
                                    <img className="w-100" src={keyone} width="680" height="200" alt="Employee Management" />
                                </div>
                                <div className="card-content p-4">
                                    <h4 className="mt-0 font-20 font-weight-bold text-left position-relative">Effortless Team Management:</h4>
                                    <ul className="f_size_13 pl_20 mb-0">
                                        <li>Build & Manage Dynamic Teams: Effortlessly add or remove members as your projects evolve.</li>
                                        <li>Real-Time Visibility: Gain instant insights into team availability and upcoming leave, allowing for proactive project planning.</li>
                                        <li>Anticipate & Overcome Challenges: Identify potential roadblocks before they arise, ensuring seamless project execution.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className="col-md-6 feature-card">
                    <Fade bottom cascade>
                        <div className="iitem p-0">
                            <div className="seo_service_item sap-card p-0">
                                <div className="service-icon">
                                    <img className="w-100" src={keytwo} width="680" height="200" alt="Employee Management" />
                                </div>
                                <div className="card-content p-4">
                                    <h4 className="mt-0 font-20 font-weight-bold text-left position-relative">Effortless Leave Request & Approval:</h4>
                                    <ul className="f_size_13 pl_20 mb-0">
                                        <li>Simplified Leave Requests: Employees submit electronic requests, specifying details and attaching relevant documents (optional).</li>
                                        <li>Streamlined Review & Approval: Managers enjoy a clear and efficient approval process, minimizing delays and fostering transparency.</li>
                                        <li>Happy Employees, Happy Teams: Empower your workforce with a user-friendly leave management system.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className="col-md-6 feature-card">
                    <Fade bottom cascade>
                        <div className="iitem p-0">
                            <div className="seo_service_item sap-card p-0">
                                <div className="service-icon">
                                    <img className="w-100" src={keythree} width="680" height="200" alt="Employee Management" />
                                </div>
                                <div className="card-content p-4">
                                    <h4 className="mt-0 font-20 font-weight-bold text-left position-relative">Gain Valuable Insights with Secure Report Management:</h4>
                                    <ul className="f_size_13 pl_20 mb-0">
                                        <li>Centralized Reporting Library: Access a comprehensive collection of company reports for various purposes.</li>
                                        <li>Data-Driven Decisions: Extract and utilize reports for tasks like attendance tracking, trend analysis, and informed decision-making.</li>
                                        <li>Enhanced Security: Maintain complete control over report access, editing, and deletion for optimal data security.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                {/*<div className="body_wrapper">*/}
                {/*<h1 className='py-5 mt-5'>Time and attendance management system</h1>*/}
                {/*/!*<Card style={{ width: '18rem' }}>*!/*/}
                {/*/!*<Card.Img variant="top" src="holder.js/100px180" />*!/*/}
                {/*/!*<Card.Body>*!/*/}
                {/*/!*<Card.Title>Card Title</Card.Title>*!/*/}
                {/*/!*<Card.Text>*!/*/}
                {/*/!*Some quick example text to build on the card title and make up the*!/*/}
                {/*/!*bulk of the card's content.*!/*/}
                {/*/!*</Card.Text>*!/*/}
                {/*/!*<Button variant="primary">Go somewhere</Button>*!/*/}
                {/*/!*</Card.Body>*!/*/}
                {/*/!*</Card>*!/*/}
                {/*</div>*/}
            </div>
        )
    }
};

export default Opportunities;