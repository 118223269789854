import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FeatureCard from '../components/Innerpages/FeatureList';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';

const FeatureList = () => {
    return (
        <div className="body_wrapper">
            {/*<CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>*/}
            <FeatureCard featureClass="feature_area font-36" imgName="innerpage/engage Pages image/Feature page images/Mask Group 52.png" Ptitle="Reports"  Pdescription=" The Reports feature gives authorised members the access to the various reports that are guarded in the company system. The feature gives you the opportunity to extract each report for a lot of different purposes like checking attendance, aggregating violation reports, sharing reports or to making changes in them."  PdescriptionTwo=" Once a report is accessed, a follow up action can be taken like deleting it or protecting it with a different passcode, only to be accessed at another point in the project timeline. " />
            {/*<Footer FooterData={FooterData}/>*/}
        </div>
    )
}
export default FeatureList;