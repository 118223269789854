import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Sservice from '../components/Service/Sservice/Sservice';
import AgencyAbout from '../components/About/AgencyAbout';
import Partner from '../components/Partner';
import Footer from '../components/Footer/Footer';
import ServiceData from '../components/Service/ServiceData';
import FooterData from '../components/Footer/FooterData';
import AboutBanner from './AboutBanner'
import { Helmet } from "react-helmet";

const About = () => {

    return(
        <>
            <Helmet>
                <title>Engage App Cloud - About Us</title>
                <meta
                    name="description"
                    content="Engage is a simple and efficient employee management tool, it helps you to plan and organize in a way which is best suitable for you and your team."
                />
                <meta
                    name="author"
                    content="Engage"
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Engage App Cloud - About Us" />
                <meta property="og:description" content="Engage is a simple and efficient employee management tool, it helps you to plan and organize in a way which is best suitable for you and your team." />
                <meta property="og:url" content="https://www.engageapp.cloud/" />

                <meta property="og:site_name" content="Engage" />
                <meta property="og:image" content="" />
                <meta property="og:image:width" content="200" />
                <meta property="og:image:height" content="200" />
                <meta property="og:image:alt" content="Engage App Cloud - About Us" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Engage is a simple and efficient employee management tool, it helps you to plan and organize in a way which is best suitable for you and your team." />
                <meta name="twitter:title" content="Engage App Cloud - About Us" />
                <meta name="twitter:site" content="https://www.engageapp.cloud/" />
                <meta name="twitter:creator" content="@engage" />
            </Helmet>
            <div className="body_wrapper">
                <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>
                {/*<Breadcrumb breadcrumbClass="breadcrumb_area" imgName="AboutUs/About_Us_banner.webp" Ptitle="About Us" Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"/>*/}
                <AboutBanner/>
                <Sservice/>
                {/*<AgencyAbout ServiceData={ServiceData}/>*/}
                {/*<Partner pClass="partner_logo_area_five bg_color"/>*/}
                <Footer FooterData={FooterData}/>
            </div>
        </>
    )
};

export default About;