import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FeatureCard from '../components/Innerpages/FeatureList';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';

const FeatureList = () => {
    return (
        <div className="body_wrapper">
            {/*<CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>*/}
            <FeatureCard featureClass="feature_area font-36" imgName="innerpage/engage Pages image/Feature page images/Group 75786.png" Ptitle="Location Management "  Pdescription=" The location from which an employee punches in at work is important for security and tacking reasons, and so, the Location Management feature give the management the power to keep tab on the same. "  PdescriptionTwo=" If the clock-in happens from outside the office premises, a request notification is sent to the admin in charge who alerts the manager about the same. A quick action can hence be taken or a clarity on the anomatous situation is obtained." />
            {/*<Footer FooterData={FooterData}/>*/}
        </div>
    )
}
export default FeatureList;