import React, {Component} from 'react';
import {Link,NavLink} from 'react-router-dom';
// import {Link as ScrollLink} from 'react-scroll';
import Sticky from 'react-stickynode';

class CustomNavbar extends Component {
    render() {
        var {mClass, nClass, cClass, slogo, hbtnClass} =this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`} custom_hed>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../img/logo2.png")} width="137" height="33" alt="Engage Blue Logo" />
                            <img src={require("../img/engage_logo.png")} width="137" height="33" alt="Engage Blue Logo" />
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                {/*<li className="nav-item dropdown submenu mega_menu mega_menu_two">*/}
                                    {/*<Link to="./" className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                                        {/*Home*/}
                                    {/*</Link>*/}
                                    {/*<div className="mega_menu_inner">*/}
                                        {/*<ul className="dropdown-menu">*/}
                                            {/*<li className="nav-item">*/}
                                                {/*<ul className="dropdown-menu scroll">*/}
                                                {/*<li className="nav-item">*/}
                                                    {/*<NavLink to="/home-chat" exact className="item">*/}
                                                        {/*<span className="img">*/}
                                                            {/*<span className="rebon_tap">New</span>*/}
                                                            {/*<img src={require('../img/mega-menu-img/home-chat.jpg')} alt="Prototyping Tool"/>*/}
                                                        {/*</span>*/}
                                                        {/*<span className="text">*/}
                                                            {/*Hoeme Chat*/}
                                                        {/*</span>*/}
                                                    {/*</NavLink>*/}
                                                {/*</li>*/}
                                                {/*<li className="nav-item">*/}
                                                    {/*<NavLink to="/Home-Tracking" exact className="item">*/}
                                                        {/*<span className="img">*/}
                                                            {/*<span className="rebon_tap">New</span>*/}
                                                            {/*<img src={require('../img/mega-menu-img/home-track.jpg')} alt="Prototyping Tool"/>*/}
                                                        {/*</span>*/}
                                                        {/*<span className="text">*/}
                                                            {/*Home Tracking*/}
                                                        {/*</span>*/}
                                                    {/*</NavLink>*/}
                                                {/*</li>*/}
                                                {/*<li className="nav-item">*/}
                                                    {/*<NavLink to="/home-event" exact className="item">*/}
                                                        {/*<span className="img">*/}
                                                            {/*<span className="rebon_tap">New</span>*/}
                                                            {/*<img src={require('../img/mega-menu-img/home-event.jpg')} alt="Event"/>*/}
                                                        {/*</span>*/}
                                                        {/*<span className="text">*/}
                                                            {/*Home Event*/}
                                                        {/*</span>*/}
                                                    {/*</NavLink>*/}
                                                {/*</li>*/}
                                                {/*<li className="nav-item">*/}
                                                    {/*<NavLink to="/home-cloud" exact className="item">*/}
                                                        {/*<span className="img">*/}
                                                            {/*<span className="rebon_tap">New</span>*/}
                                                            {/*<img src={require('../img/mega-menu-img/home12.jpg')} alt="cloud"/>*/}
                                                        {/*</span>*/}
                                                        {/*<span className="text">*/}
                                                            {/*Cloud Based Saas*/}
                                                        {/*</span>*/}
                                                    {/*</NavLink>*/}
                                                {/*</li>*/}
                                                {/*<li className="nav-item">*/}
                                                    {/*<NavLink to="/home-erp" exact className="item">*/}
                                                        {/*<span className="img">*/}
                                                            {/*<img src={require('../img/mega-menu-img/erp.jpg')} alt="Prototyping Tool"/>*/}
                                                        {/*</span>*/}
                                                        {/*<span className="text">*/}
                                                            {/*Hoeme ERP*/}
                                                        {/*</span>*/}
                                                    {/*</NavLink>*/}
                                                {/*</li>*/}
                                                {/*<li className="nav-item">*/}
                                                    {/*<NavLink to="/home-hosting" exact className="item">*/}
                                                        {/*<span className="img">*/}
                                                            {/*<img src={require('../img/mega-menu-img/hosting.jpg')} alt="Prototyping Tool"/>*/}
                                                        {/*</span>*/}
                                                        {/*<span className="text">*/}
                                                            {/*Hoeme Hosting*/}
                                                        {/*</span>*/}
                                                    {/*</NavLink>*/}
                                                {/*</li>*/}
                                                {/*<li className="nav-item">*/}
                                                    {/*<NavLink to="/home-security" exact className="item">*/}
                                                        {/*<span className="img">*/}
                                                            {/*<img src={require('../img/mega-menu-img/home-security.jpg')} alt="Prototyping Tool"/>*/}
                                                        {/*</span>*/}
                                                        {/*<span className="text">*/}
                                                            {/*Hoeme Security*/}
                                                        {/*</span>*/}
                                                    {/*</NavLink>*/}
                                                {/*</li>*/}
                                                {/*<li className="nav-item">*/}
                                                    {/*<NavLink to="/home-support" exact className="item">*/}
                                                        {/*<span className="img">*/}
                                                            {/*<img src={require('../img/mega-menu-img/support.jpg')} alt="Prototyping Tool"/>*/}
                                                        {/*</span>*/}
                                                        {/*<span className="text">*/}
                                                            {/*Hoeme Support*/}
                                                        {/*</span>*/}
                                                    {/*</NavLink>*/}
                                                {/*</li>*/}
                                                {/*<li className="nav-item">*/}
                                                        {/*<NavLink to="/landing" exact className="item">*/}
                                                            {/*<span className="img">*/}
                                                                {/*<img src={require('../img/mega-menu-img/home14.jpg')} alt="Prototyping Tool"/>*/}
                                                            {/*</span>*/}
                                                            {/*<span className="text">*/}
                                                                {/*App Landing (One Page)*/}
                                                            {/*</span>*/}
                                                        {/*</NavLink>*/}
                                                    {/*</li>*/}
                                                    {/*<li className="nav-item">*/}
                                                        {/*<NavLink to="/" exact className="item">*/}
                                                            {/*<span className="img">*/}
                                                                {/*<img src={require('../img/mega-menu-img/Prototyping_Tool.jpg')} alt="Prototyping Tool"/>*/}
                                                            {/*</span>*/}
                                                            {/*<span className="text">*/}
                                                                {/*Prototyping Tool*/}
                                                            {/*</span>*/}
                                                        {/*</NavLink>*/}
                                                    {/*</li>*/}
                                                    {/*<li className="nav-item">*/}
                                                        {/*<NavLink to="/payment-processing" exact className="item">*/}
                                                            {/*<span className="img">*/}
                                                                {/*<img src={require('../img/mega-menu-img/home16.jpg')} alt="Image"/>*/}
                                                            {/*</span>*/}
                                                            {/*<span className="text">*/}
                                                                {/*Payment Processing*/}
                                                            {/*</span>*/}
                                                        {/*</NavLink>*/}
                                                    {/*</li>*/}
                                                    {/*<li className="nav-item">*/}
                                                        {/*<NavLink to="/Startup" exact className="item">*/}
                                                            {/*<span className="img">*/}
                                                                {/*<img src={require('../img/mega-menu-img/home15.jpg')} alt="Image"/>*/}
                                                            {/*</span>*/}
                                                            {/*<span className="text">*/}
                                                                {/*Startup*/}
                                                            {/*</span>*/}
                                                        {/*</NavLink>*/}
                                                    {/*</li>*/}
                                                    {/*<li className="nav-item">*/}
                                                        {/*<NavLink to="/digital-marketing" className="item">*/}
                                                            {/*<span className="img">*/}
                                                                {/*<img src={require('../img/mega-menu-img/home11.jpg')} alt="Digital Marketing"/>*/}
                                                            {/*</span>*/}
                                                            {/*<span className="text">*/}
                                                                {/*Digital Marketing*/}
                                                            {/*</span>*/}
                                                        {/*</NavLink>*/}
                                                    {/*</li>*/}
                                                    {/*<li className="nav-item">*/}
                                                        {/*<NavLink to="/HR-Management" className="item">*/}
                                                            {/*<span className="img">*/}
                                                                {/*<img src={require('../img/mega-menu-img/home4.jpg')} alt="HR Management"/>*/}
                                                            {/*</span>*/}
                                                            {/*<span className="text">*/}
                                                                {/*HR Management*/}
                                                            {/*</span>*/}
                                                        {/*</NavLink>*/}
                                                    {/*</li>*/}
                                                    {/*<li className="nav-item">*/}
                                                        {/*<NavLink to="/Home-CRM" exact className="item">*/}
                                                            {/*<span className="img">*/}
                                                                {/*<img src={require('../img/mega-menu-img/home3.jpg')} alt="CRM Software"/>*/}
                                                            {/*</span>*/}
                                                            {/*<span className="text">*/}
                                                                {/*CRM Software*/}
                                                            {/*</span>*/}
                                                        {/*</NavLink>*/}
                                                    {/*</li>*/}
                                                {/*</ul>*/}
                                            {/*</li>*/}
                                        {/*</ul>*/}
                                    {/*</div>*/}
                                {/*</li>*/}
                                
                                {/*<li className="dropdown submenu nav-item">*/}
                                    {/*<Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Service</Link>*/}
                                    {/*<ul role="menu" className=" dropdown-menu">*/}
                                        {/*<li className="nav-item"><NavLink exact title="Service" className="nav-link" to='/Service'>Service</NavLink></li>*/}
                                        {/*<li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/service-details'>Service Details</NavLink></li>*/}
                                    {/*</ul>*/}
                                {/*</li>*/}
                                {/*<li className="dropdown submenu nav-item">*/}
                                    {/*<Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Pages</Link>*/}
                                    {/*<ul role="menu" className=" dropdown-menu">*/}
                                        {/*<li className="nav-item"><NavLink exact title="About" className="nav-link" to='/About'>About</NavLink></li>*/}
                                        {/*<li className="nav-item"><NavLink exact title="Process" className="nav-link" to='/Process'>Process</NavLink></li>*/}
                                        {/*<li className="nav-item"><NavLink exact title="Team" className="nav-link" to='/Team'>Team</NavLink></li>*/}
                                        {/*<li className="nav-item"><NavLink exact title="Price" className="nav-link" to='/price'>Price</NavLink></li>*/}
                                        {/*<li className="nav-item"><NavLink exact title="Faq" className="nav-link" to='/faq'>Faq</NavLink></li>*/}
                                        {/*<li className="nav-item"><NavLink exact title="SignIn" className="nav-link" to='/sign-in'>Sign In</NavLink></li>*/}
                                        {/*<li className="nav-item"><NavLink exact title="SignUp" className="nav-link" to='/sign-up'>Sign Up</NavLink></li>*/}
                                    {/*</ul>*/}
                                {/*</li>*/}
                                {/*<li className="dropdown submenu nav-item"><Link title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" to="#">Portfolio</Link>*/}
                                    {/*<ul role="menu" className=" dropdown-menu">*/}
                                        {/*<li className="nav-item"><NavLink title="Portfolio 2" className="nav-link" to='/Portfolio-2col'>Portfolio 2col</NavLink></li>*/}
                                        {/*<li className="nav-item"><NavLink title="Portfolio 3" className="nav-link" to='/Portfolio-3col'>Portfolio 3col</NavLink></li>*/}
                                        {/*<li className="nav-item"><NavLink title="Portfolio Fullwidth" className="nav-link" to='/Portfolio-fullwidth-4col'>Portfolio fullwidth</NavLink></li>*/}
                                        {/*<li className="nav-item"><NavLink title="PortfolioSingle" className="nav-link" to='/PortfolioSingle'>Portfolio Single</NavLink></li>*/}
                                    {/*</ul>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item dropdown submenu">*/}
                                    {/*<a className="nav-link dropdown-toggle" href=".#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                                        {/*Blog*/}
                                    {/*</a>*/}
                                    {/*<ul className="dropdown-menu">*/}
                                        {/*<li className="nav-item"><NavLink to="/Bloglist" className="nav-link">Blog List</NavLink></li>*/}
                                        {/*<li className="nav-item"><NavLink to="/BlogGridPage" className="nav-link">Blog Grid</NavLink></li>*/}
                                        {/*<li className="nav-item"><NavLink to="/BlogSingle" className="nav-link">Blog Single</NavLink></li>*/}
                                    {/*</ul>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item"><NavLink title="Pricing" className="nav-link" to="/Contact">Contact</NavLink></li>*/}

                                <li className="nav-item">
                                    <NavLink title="Features" className="nav-link" to="/features">Features</NavLink>
                                </li>



        {/*<li className="dropdown submenu nav-item">*/}
            {/*<Link to="/Features" title="Features"*/}
                  {/*className={window.location.pathname === '/employee-management' || window.location.pathname === '/location-management' || window.location.pathname === '/shift-management' || window.location.pathname === '/role-management'  || window.location.pathname === '/feature-access-management'  || window.location.pathname === '/leave-management'  || window.location.pathname === '/team-management'  || window.location.pathname === '/reports'  ?  "dropdown-toggle nav-link active" : "dropdown-toggle nav-link" } data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Features</Link>*/}
            {/*<ul role="menu" className="dropdown-menu">*/}
                {/*<li className="nav-item"><NavLink exact title="Employee Management" className="nav-link" to='/employee-management'>Employee Management</NavLink></li>*/}
                {/*<li className="nav-item"><NavLink exact title="Location management" className="nav-link" to='/location-management'>Location management</NavLink></li>*/}
                {/*<li className="nav-item"><NavLink exact title="Shift management" className="nav-link" to='/shift-management'>Shift management</NavLink></li>*/}
                {/*<li className="nav-item"><NavLink exact title="Role Management" className="nav-link" to='/role-management'>*/}
                    {/*Role Management</NavLink></li>*/}
                {/*<li className="nav-item"><NavLink exact title="Feature Access Management" className="nav-link" to='/feature-access-management'>Feature Access Management</NavLink></li>*/}
                {/*<li className="nav-item"><NavLink exact title="Leave Management" className="nav-link" to='/leave-management'>Leave Management</NavLink></li>*/}
                {/*<li className="nav-item"><NavLink exact title="Team Management" className="nav-link" to='/team-management'>Team Management</NavLink></li>*/}
                {/*<li className="nav-item"><NavLink exact title="Reports" className="nav-link" to='/reports'>Reports</NavLink></li>*/}

            {/*</ul>*/}
        {/*</li>*/}

        <li className="dropdown submenu nav-item">
            <Link to="./" title="Industries"
                  className={window.location.pathname === '/education' || window.location.pathname === '/health-care' || window.location.pathname === '/hospitality' ? "dropdown-toggle nav-link active" : "dropdown-toggle nav-link" } data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Industries</Link>
            <ul role="menu" className="dropdown-menu">
                <li className="nav-item"><NavLink exact title="IrisID" className="nav-link" to='/education'>Education</NavLink></li>
                <li className="nav-item"><NavLink exact title="Healthcare" className="nav-link" to='/health-care'>Health Care</NavLink></li>
                <li className="nav-item"><NavLink exact title="Hospitality" className="nav-link" to='/hospitality'>Hospitality</NavLink></li>
            </ul>
        </li>

        <li className="dropdown submenu nav-item">
            <Link to="./" title="Device" className={window.location.pathname === '/irisid' ? "dropdown-toggle nav-link active" : "dropdown-toggle nav-link" } data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Device</Link>
            <ul role="menu" className="dropdown-menu">
            <li className="nav-item"><NavLink exact title="IrisID" className="nav-link" to='/irisid'>IrisID</NavLink></li>

            </ul>
        </li>

        {/*<li className="nav-item">*/}
            {/*<NavLink title="Resource" className="nav-link" to="/Resource">Resource</NavLink>*/}
        {/*</li>*/}
        <li className="nav-item">
            <NavLink title="About" className="nav-link" to="/about">About Us</NavLink>
        </li>


                                {/*<li className="nav-item">*/}
                                    {/*<NavLink title="Contact Us" className="nav-link" to="/Contact">Contact Us</NavLink>*/}
                                {/*</li>*/}







                                {/*{window.location.pathname === '/' ?*/}
                                    {/*<>*/}
                                        {/*<li className="nav-item">*/}
                                            {/*<ScrollLink className="nav-link" activeClass="active" to="features" spy={true} smooth={true} offset={-90} duration={1000}>Features</ScrollLink>*/}
                                        {/*</li>*/}
                                        {/*<li className="nav-item">*/}
                                            {/*<ScrollLink className="nav-link" activeClass="active" to="contact" spy={true} smooth={true} offset={-90} duration={1000}>Contact Us</ScrollLink>*/}
                                        {/*</li>*/}
                                    {/*</> :*/}
                                    {/*null*/}
                                {/*}*/}


                                {/*<li className="dropdown submenu nav-item">*/}
                                    {/*<Link to="./" title="Industries" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Industries</Link>*/}
                                    {/*<ul role="menu" className=" dropdown-menu">*/}
                                        {/*<li className="nav-item"><NavLink exact title="Education" className="nav-link" to=''>Education</NavLink></li>*/}
                                        {/*<li className="nav-item"><NavLink exact title="Healthcare" className="nav-link" to=''>Healthcare</NavLink></li>*/}
                                        {/*<li className="nav-item"><NavLink exact title="Hospitality" className="nav-link" to=''>Hospitality</NavLink></li>*/}

                                    {/*</ul>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item"><NavLink exact title="Resource" className="nav-link" to=''>Resource</NavLink></li>*/}

                            </ul>



                            <a className={`btn_get btn_hover `} href="https://track.engageapp.cloud/login">Sign In</a>

                            {/*<a className={`btn_get btn_hover ${hbtnClass}`} href="#get-app">Get Started</a>*/}
                    <NavLink title="Contact Us" className="btn_get btn_hover new_btn" to="/schedule-demo">Get Started</NavLink>

                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;