import React from 'react';
import Reveal from 'react-reveal/Reveal/';
import CustomNavbar from '../components/CustomNavbar';
import {NavLink} from 'react-router-dom';
import FeatureCard from '../components/Innerpages/FeatureList';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';

const FeatureList = () => {
    return (
        <div className="body_wrapper">
            {/*<CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>*/}
            <FeatureCard featureClass="feature_area employee-management pb-0 font-36" imgName="innerpage/engage Pages image/Feature page images/Group 75789.png" Ptitle="Employee Management "  Pdescription="Manage employee activities with a channel of clear communication with the Employee Management feature. The system creates an environment that helps you review the permissions raised by employees and approve them so lags in workflow are minimised. You can also track and assess employee performance, employee grievances and employee suggestions as put forth."  PdescriptionTwo=" Keep a track of employee absenteeism and their clock in/clock out timings to maintain uniformity and keep track of their overtimes while chalking out performance incentives. The feature also helps you keep tabs on employee location during their stipulated hours of work." />
            {/*<Footer FooterData={FooterData}/>*/}
        </div>
    )
}
export default FeatureList;