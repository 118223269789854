import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';
import {Link} from 'react-router-dom'


const NotFound = () => (
  <div className="body_wrapper">
    <CustomNavbar hbtnClass="new_btn" />
    <section className="error_two_area">
      <div className="container flex">
        <div className="row align-items-center">
          <div className="col-6">
            <img className="error-img img-fluid" src={require("../img/new/error.png")} alt="404-banner" />
          </div>  
          <div className="col-6 text-center">  
            <h1 className="error-heading">Page not Found</h1>
            <p>We’re sorry, the page you’re looking for could not be found.</p>
            <p className="pb-3">Please go back to the homepage</p>
            <Link to={""} className="home-btn" data-bs-toggle="modal" data-bs-target="#home-modal">
              Go Back to Home </Link>
          </div>
        </div>
      </div>
    </section>
    <Footer FooterData={FooterData}/>
  </div>
);
export default NotFound;
