import React, {Component} from 'react';
import Fade from 'react-reveal/Fade';
import Stserviceitems from './StserviceItem';
import Reveal from 'react-reveal/Reveal';
class Sservice extends Component{
    render(){
        return(
            <section className="agency_service_area about-us-main">
                <div className="container">
                    <div className="row mb_30">
                        <Fade bottom cascade>
                        {/*<div className="col-lg-12 col-md-12 py-4">*/}
                            {/*<h2 className="f_size_30 f_600 t_color3 l_height40 wow fadeInUp" data-wow-delay="0.2s">Engage</h2>*/}
                            {/*<p>Engage is a simple and efficient employee management tool, it helps you to plan and organize in a way which is best suitable for you and your team. Engage is aimed at, giving you and your team the ability to manage your self by collaborating with watch other. To this extant engage takes care of all small miscellaneous tasks associated with managing, by providing you relevant information on your fingertips and gives you and your team the opportunity to focus on the work which matters.</p>*/}
                            {/*<p>Our aim with engage is to provide the best working experience which is possible to you and your team, in or out of the work place. </p>*/}
                        {/*</div>*/}
                        <div className="col-lg-12 col-md-12 py-4">
                            <h2 className="f_size_30 f_600 t_color3 l_height40 wow fadeInUp pb-4" data-wow-delay="0.2s">Exalogic Consulting</h2>
                            <p>Engage is a proprietary developed by Exalogic Consulting. With close to two decades of expertise in ERP consulting, IT product development and integration, we have grown to become a globally recognised IT consulting firm with a presence in America, the Middle East, Europe and Asia. Our close-knit team of engineers, business consultants and developers have uniquely empowered us to be at the forefront of the exciting new developments in digital technology that promises to revolutionize IT. Our pedigree includes conceptualizing, implementing and managing third wave turnkey solutions for some of the largest property management firms in the world.</p>
                        </div>
                        </Fade>
                    </div>
                    <div className="row mt-5 about_table">
                        <Fade bottom cascade>
                            <div className="col-lg-6 col-md-12 p-0">
                                <div className="col-lg-12 col-md-12 pt-3 pb-2 about_table_hed">
                                    <h2 className="f_size_30 f_600 t_color3 l_height40 wow fadeInUp" data-wow-delay="0.2s">Our Mission</h2>
                                </div>
                                <div className="col-lg-12 col-md-12 py-4">
                                    <p>We aim to empower the managers and employees in many industries with the benefits of emerging digital technologies through transformative interventions.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 p-0">
                                <div className="col-lg-12 col-md-12 pt-3 pb-2 about_table_hed">
                                    <h2 className="f_size_30 f_600 t_color3 l_height40 wow fadeInUp" data-wow-delay="0.2s">Our Vision</h2>
                                </div>
                                <div className="col-lg-12 col-md-12 py-4">
                                    <p>We envision a future where a better human resource management experience is the norm without the limitations imposed by traditional modes of managing. We hope to build a better experience for the employees and their managers and provide an environment which aimed at maximizing the productivity of everyone.</p>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        )
    }
}
export default Sservice;