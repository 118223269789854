import React from 'react';
import Reveal from 'react-reveal/Reveal/';
import Fade from 'react-reveal/Fade';
import { Helmet } from "react-helmet";

const EducationPage = () => {
    return(
        <>
            <Helmet>
                <title>Educational Organization Staff Management Software</title>
                <meta
                    name="description"
                    content="Dedicated platform for education industry to manage payroll, seamless communication and attendance with real time experience."
                />
                <meta
                    name="author"
                    content="Engage"
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Educational Organization Staff Management Software" />
                <meta property="og:description" content="Dedicated platform for education industry to manage payroll, seamless communication and attendance with real time experience." />
                <meta property="og:url" content="https://www.engageapp.cloud/" />

                <meta property="og:site_name" content="Engage" />
                <meta property="og:image" content="" />
                <meta property="og:image:width" content="200" />
                <meta property="og:image:height" content="200" />
                <meta property="og:image:alt" content="Educational Organization Staff Management Software" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Dedicated platform for education industry to manage payroll, seamless communication and attendance with real time experience." />
                <meta name="twitter:title" content="Educational Organization Staff Management Software" />
                <meta name="twitter:site" content="https://www.engageapp.cloud/" />
                <meta name="twitter:creator" content="@engage" />
            </Helmet>
            <div className="sec_pad top_space">
                <section>
                    <div>

                        <div className="row justify-content-end align-items-center text-center">
                            <div className="col-xs-12 col-lg-12 col-md-12 seo_sec_title text-center justify-content-end">
                                <div className="payroll-img">
                                    <img src={require('../../img/innerpage/engage Pages image/Education banner and images/Group 75984.png')} width="1519" height="616" className="img-fluid" alt="Education Banner"/>
                                </div>
                            </div>
                            {/*<div className="col-xs-12 col-lg-6 col-md-12 seo_sec_title text-center">*/}
                                {/*<Reveal effect="fadeInUp" duration={500}>*/}
                                {/*<h1 className="education-title text-uppercase font-weight-semibold">Education</h1>*/}
                                {/*</Reveal>*/}
                                {/*<Reveal effect="fadeInUp" duration={800}>*/}
                                    {/*<p>Track time smarter with Jibble, and allow your students and staff to focus on what’s really important.</p>*/}
                                {/*</Reveal>*/}
                            {/*</div>*/}
                            {/*<div className="col-xs-12 col-lg-3 seo_sec_title text-center justify-content-end">*/}
                                {/*<div className="payroll-img">*/}
                                    {/*<img src={require('../img/innerpage/engage Pages image/IrisID/Group 76310.png')} className="img-fluid" alt="Image"/>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </section>

                {/*<section className="track_attendance sec_pad">*/}
                    {/*<div className="container">*/}
                        {/*<div className="seo_sec_title text-center">*/}
                            {/*<Reveal effect="fadeInUp" duration={1300}><h2>Track attendance, anywhere</h2></Reveal>*/}
                            {/*<Reveal effect="fadeInUp" duration={1600}><p>Teaching staff or students can track time with their mobile phone with GPS, use a shared time clock, or punch in from their computer. </p></Reveal>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <section className="seo_features_one py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 d-flex align-items-center  justify-content-end">
                                <Fade bottom cascade>
                                    <div className="seo_features_content max-payroll">
                                        <h1 className="mb-3 wow fadeInUp">Track your Time</h1>
                                        <p className="wow fadeInUp">With just 24 hours in hand, every minute is of essencel Help your teaching staff and students keep a track of their precious time so they have a more productive day.</p>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="payroll-img">
                                    <img src={require('../../img/innerpage/engage Pages image/Education banner and images/Get oversight on your floors and corridors.png')}  className="img-fluid payroll-img_img" alt="Track your Time"  height="424" width="570"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="seo_features_one circle-before py-5">
                    <div className="container">
                        <div className="row flex-row-reverse">
                            <div className="col-lg-6 col-md-12 d-flex align-items-center">
                                <Fade bottom cascade>
                                    <div className="seo_features_content max-payroll">
                                        <h2 className="mb-3 wow fadeInUp">Note Attendance</h2>
                                        <p className="wow fadeInUp">We help you note and update the attendance of your students, teaching staff and non-teaching staff in real- time ensuring, gaps are filled on the go, and no lags are experienced</p>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 col-md-12 justify-content-end">
                                <div className="payroll-img">
                                    <img src={require('../../img/innerpage/engage Pages image/Education banner and images/OBJECTS.png')} width="570" height="565" className="img-fluid payroll-img_img" alt="Note Attendance"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="seo_features_one py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 d-flex align-items-center  justify-content-end">
                                <Fade bottom cascade>
                                    <div className="seo_features_content max-payroll">
                                        <h2 className="mb-3 wow fadeInUp">Manage Payroll</h2>
                                        <p className="wow fadeInUp">With automated timesheets and up to date attendance lists, maintain and manage the payroll of all faculties across al grades without any hassles.</p>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="payroll-img">
                                    <img src={require('../../img/innerpage/engage Pages image/Education banner and images/Time & payroll management for your school (1).png')} width="570" height="374" className="img-fluid payroll-img_img" alt="Manage Payroll" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="seo_features_one py-5">
                    <div className="container">
                        <div className="row flex-row-reverse">
                            <div className="col-lg-6 col-md-12 d-flex align-items-center">
                                <Fade bottom cascade>
                                    <div className="seo_features_content max-payroll">
                                        <h2 className="mb-3 wow fadeInUp">Seamless Communication and passing of information</h2>
                                        <p className="wow fadeInUp">Verify attendance data with real-time location tracking and automatic facial recognition Give parents access to selfies for extra piece of mind.</p>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="payroll-img">
                                    <img src={require('../../img/innerpage/engage Pages image/Education banner and images/Group 75982.png')} width="570" height="553" className="img-fluid payroll-img_img" alt="Seamless Communication and passing of information" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="seo_features_one">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 d-flex align-items-center  justify-content-end">
                                <Fade bottom cascade>
                                    <div className="seo_features_content max-payroll">
                                        <h2 className="mb-3 wow fadeInUp">Geofence your Work Locations</h2>
                                        <p className="wow fadeInUp">Set up a virtual barrier using the Engage app's Geofencing feature to track and protect the mobility of your students, teachers, and non-teaching personnel. If defined time or functionality regulations are broken, the app will send a message and an automated report will be issued for further action. </p>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="payroll-img">
                                    <img src={require('../../img/innerpage/engage Pages image/Education banner and images/Group 75983.png')} width="570" height="482" className="img-fluid payroll-img_img" alt="Geofence your Work Locations" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
export default EducationPage;