import React from "react";
import Fade from "react-reveal/Fade";
import RequestDemoModal from "../RequestDemoModal/RequestDemoModal";

const Banner = () => {
  return (
    <div className="body_wrapper">
      <section className="sec_pad time-mng-system lp-two-banner pb-0">
        <div className="container pb-md-4 h-100">
          <div className="h-100 py-lg-3">
            <div className="row align-items-center my-2">
              <div className="col-lg-7 col-md-8 align-self-center mb-4 mb-md-0">
                <Fade bottom cascade>
                  <div>
                    <h1 className="pt-lg-0 pt-2 pt-sm-3 banner-txt mb-4 text-white">
                      Secure Your Data & Empower Your Teams: Unleash the Power of Role and Feature Access Management with Engage.
                    </h1>
                    <p className="lems-text mb-4 mb-md-4">
                      Tired of managing user permissions one by one? Our advanced access control system simplifies user management and empowers your teams.
                    </p>
                    <div className="d-flex flex-column flex-sm-row">
                      <button
                        data-toggle="modal"
                        data-target="#getDemoBtp"
                        className="btn_three mt-2 btn-hover"
                      >
                        Request a Demo
                      </button>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <RequestDemoModal />
    </div>
  );
};

export default Banner;
