import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FeatureCard from '../components/Innerpages/FeatureList';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';

const FeatureList = () => {
    return (
        <div className="body_wrapper">
            {/*<CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>*/}
            <FeatureCard featureClass="feature_area font-36" imgName="innerpage/engage Pages image/Feature page images/Group 75788.png" Ptitle="Leave Management " Pdescription="The Leave Management feature creates a well chalked out path for employees to apply for leaves whenever required. It gives them an opportunity to specify a reason and attach supporting documents (if any). " PdescriptionTwo="The manager is then in a position to review and-approve/reject the same without any hassles. The feature ensures that the process of leave application is made easy and is carried out promptly. " />
            {/*<Footer FooterData={FooterData}/>*/}
        </div>
    )
}
export default FeatureList;