import React from "react";
import Fade from "react-reveal/Fade";
import empimg from "../../img/time/role-mgmt.webp";
import { Link } from "react-router-dom";
const Role = () => {
    return (
        <div className="body_wrapper">
            <section className="experience-section role-mgmt-bg py-md-4 py-lg-5 py-3">
                <div className="container pb-sm-10">
                    <div className="row mb-2">
                        <div className="col-md-5 mb-3 mb-md-0 mb-lg-0">
                            <img className="img-fluid" src={empimg} width="" height="" alt="Employee Management" />
                        </div>
                        <div className="col-md-7">
                            <div className="sec-one">
                                <h2 className="exp-main-head font-weight-bold mb-2">Role Management</h2>
                                <h4 className="exp-sub-head mb-2">Empower Teams with Granular Role-Based Access Control</h4>
                                <ul className="exp-sub-para font-weight-normal pl_20">
                                    <li className="">Establish a clear hierarchy of user permissions based on job roles.</li>
                                    <li>Restrict access to sensitive features and data based on designated roles.</li>
                                    <li>Promote transparency by providing team members visibility into individual access levels.</li>
                                    <li>Foster efficient collaboration by ensuring everyone has the necessary permissions to complete their tasks.</li>
                                </ul>
                            </div>
                            <div className="sec-two">
                                <h2 className="exp-main-head font-weight-bold mb-2">Feature Access Management</h2>
                                <h4 className="exp-sub-head mb-2">Enhanced Security and Flexibility with Feature Access Management</h4>
                                <p className="exp-sub-para font-weight-normal">While Role Management establishes a general permission structure, Feature Access Management provides an additional layer of control. This feature empowers authorized personnel to grant temporary access to specific features outside of their designated roles.
                                    This functionality proves invaluable during emergencies or planned absences. It ensures critical tasks remain on schedule by allowing colleagues to access necessary features, promoting continuity and minimizing disruptions.</p>
                            </div>
                            <Link to="/employee-role-and-feature-access-management-solution"
                                className="btn_three mt-2"
                            >
                                Read More
                            </Link>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
};

export default Role;
