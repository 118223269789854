import React from "react";
import Fade from "react-reveal/Fade";
import empimg from "../../img/time/team-mgmt.webp";
import { Link } from "react-router-dom";
const Team = () => {
    return (
        <div className="body_wrapper">
            <section className="experience-section py-md-4 py-lg-5 py-3">
                <div className="container pb-sm-10">
                    <div className="row mb-2">
                        <div className="col-md-7">
                            <div className="sec-one">
                                <h2 className="exp-main-head font-weight-bold mb-2">Team Management</h2>
                                <h4 className="exp-sub-head mb-2">Streamline Collaboration with Powerful Team Management</h4>
                                <ul className="exp-sub-para font-weight-normal pl_20">
                                    <li className="">Build and manage teams efficiently by adding or removing members as needed.</li>
                                    <li>Gain real-time insights into team status, including member availability and upcoming leave.</li>
                                    <li>Leverage this information to plan effectively, anticipate potential challenges, and ensure seamless project execution.</li>
                                </ul>
                            </div>
                            <div className="sec-two">
                                <h2 className="exp-main-head font-weight-bold mb-2">Leave Management</h2>
                                <h4 className="exp-sub-head mb-2">Effortless Leave Request and Approval</h4>
                                <ul className="exp-sub-para font-weight-normal pl_20">
                                    <li className="">Employees can submit leave requests electronically, specifying reason and attaching relevant documents (if needed).</li>
                                    <li>Managers enjoy a streamlined review and approval process, minimizing delays and ensuring timely decisions.</li>
                                    <li>This feature promotes transparency and simplifies leave management for both employees and managers.</li>
                                </ul>
                            </div>
                            <Link to="/leave-approval-and-team-management-software-solution"
                                className="btn_three mt-2 mb-3 mb-md-0 mb-lg-0"
                            >
                                Read More
                            </Link>
                        </div>
                        <div className="col-md-5">
                            <img className="img-fluid" src={empimg} width="" height="" alt="Employee Management" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Team;
