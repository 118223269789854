import React from 'react';
import Reveal from 'react-reveal/Reveal/';
import Fade from 'react-reveal/Fade';
import { Helmet } from "react-helmet";

const HospitalityPage = () => {
    return(
        <>
            <Helmet>
                <title>Hospitality Industry Workflow Management System</title><meta
                    name="description"
                    content="Provide your guest an amazing experience with hotel staff scheduling and activities with engage to ensuring lasting customer relationship."
                />
                <meta
                    name="author"
                    content="Engage"
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Hospitality Industry Workflow Management System" />
                <meta property="og:description" content="Provide your guest an amazing experience with hotel staff scheduling and activities with engage to ensuring lasting customer relationship." />
                <meta property="og:url" content="https://www.engageapp.cloud/" />

                <meta property="og:site_name" content="Engage" />
                <meta property="og:image" content="" />
                <meta property="og:image:width" content="200" />
                <meta property="og:image:height" content="200" />
                <meta property="og:image:alt" content="Hospitality Industry Workflow Management System" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Provide your guest an amazing experience with hotel staff scheduling and activities with engage to ensuring lasting customer relationship." />
                <meta name="twitter:title" content="Hospitality Industry Workflow Management System" />
                <meta name="twitter:site" content="https://www.engageapp.cloud/" />
                <meta name="twitter:creator" content="@engage" />
            </Helmet>
            <div className="sec_pad top_space">
                <section className="healthcare-banner pb-0">
                            <div className="row justify-content-end align-items-center flex-row-reverse">
                                <div className="col-xs-12 col-lg-12 justify-content-end">
                                    <img src={require('../../img/innerpage/engage Pages image/Hospitality Banner and images/Group 76308.png')} width="1519" height="616" className="img-fluid" alt="Hospitality Banner"/>
                                </div>
                                {/*<div className="col-xs-12 col-lg-6 seo_sec_title">*/}
                                {/*<div className="max-460">*/}
                                    {/*<Reveal effect="fadeInUp" duration={500}>*/}
                                    {/*<h1 className="education-title text-uppercase font-weight-semibold">Hospitality</h1></Reveal>*/}
                                    {/*<Reveal effect="fadeInUp" duration={800}> <p>Get the team in on time, and keep everything running like clockwork - so your focus can beIn a hectic industry like hospitality, Jibble allows you to ensure hours aren’t being lost. the wellbeing of patients.</p>*/}

                                        {/*/!*<NavLink title="Get start Now- Its free" className="mt-5 seo_btn seo_btn_one btn_hover wow fadeInUp" to="/ScheduleDemo">Get start Now- Its free</NavLink>*!/*/}
                                    {/*</Reveal>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                        </div>
                </section>

                {/*<section className="track_attendance sec_pad">*/}
                    {/*<div className="container">*/}
                        {/*<div className="seo_sec_title text-center">*/}
                            {/*<Reveal effect="fadeInUp" duration={1300}><h2>Don’t just take our word for it</h2></Reveal>*/}
                            {/*<Reveal effect="fadeInUp" duration={1600}><p>Whether physicians are in consultation rooms or making patient rounds, tracking time is easy and accessible with their personal mobile devices.</p></Reveal>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <section className="seo_features_one pt-5 pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex align-items-center  justify-content-end">
                                <Fade bottom cascade>
                                    <div className="seo_features_content max-payroll">
                                        <h1 className="wow fadeInUp mb-3">Clock in to serve</h1>
                                        <p className="wow fadeInUp">They say guest is God, and that timeliness is a divine virtue. Help your employees cater to all your guests better by showing up on time to provide them an experience they will never forget! </p>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6">
                                <div className="payroll-img">
                                    <img src={require('../../img/innerpage/engage Pages image/Hospitality Banner and images/Group 76304.png')} width="570" height="340" className="img-fluid payroll-img_img" alt="Clock in to serve" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="seo_features_one circle-before pt-5 pb-3">
                    <div className="container">
                        <div className="row flex-row-reverse">
                            <div className="col-lg-6 d-flex align-items-center  ">
                                <Fade bottom cascade>
                                    <div className="seo_features_content max-payroll">
                                        <h2 className="wow fadeInUp mb-3">Get a grip over non-permanent staff and their activities</h2>
                                        <p className="wow fadeInUp">The part-time schedules of interns and apprentices can be haphazard. With alerts of tardy or help supervisors maintain a tight lid on things and offer incentives so the staff feels appreciated! </p>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 justify-content-end">
                                <div className="payroll-img">
                                    <img src={require('../../img/innerpage/engage Pages image/Hospitality Banner and images/Group 76305.png')} width="570" height="410" className="img-fluid payroll-img_img" alt="Get a grip" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="seo_features_one pt-5 pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex align-items-center  justify-content-end">
                                <Fade bottom cascade>
                                    <div className="seo_features_content max-payroll">
                                        <h2 className="mb-3 wow fadeInUp">Collaborate without delay</h2>
                                        <p className="wow fadeInUp">Successful collaboration is key when achieving a task. We help you reach out to anyone across departments and hierarchies, so you make that mission possible!</p>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6">
                                <div className="payroll-img">
                                    <img src={require('../../img/innerpage/engage Pages image/Hospitality Banner and images/Group 76306.png')} width="570" height="400" className="img-fluid payroll-img_img" alt="Collaborate without delay" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="seo_features_one circle-before pt-5 pb-3">
                    <div className="container">
                        <div className="row flex-row-reverse">
                            <div className="col-lg-6 d-flex align-items-center  ">
                                <Fade bottom cascade>
                                    <div className="seo_features_content max-payroll">
                                        <h2 className="mb-3 wow fadeInUp">Track Shift Progress</h2>

                                        <p className="wow fadeInUp">From concierge to bellboys, track the progress of shifts and activities to help your hotel staff stay on schedule, every time.</p>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 justify-content-end">
                                <div className="payroll-img">
                                    <img src={require('../../img/innerpage/engage Pages image/Hospitality Banner and images/Group 76307.png')} width="570" height="446" className="img-fluid payroll-img_img" alt="Track Shift Progress" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="seo_features_one pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex align-items-center  justify-content-end">
                                <Fade bottom cascade>
                                    <div className="seo_features_content max-payroll">
                                        <h2 className="mb-3 wow fadeInUp">Geofence your Work Locations</h2>
                                        <p className="wow fadeInUp">Customize hotel employee and visitor entry and exit reports with Engage App, and track trip history with relevant data. Using RFIDs, fingerprint, and face recognition technology to geofence every visitor's journey will help you improve the safety of your residents. </p>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6">
                                <div className="payroll-img">
                                    <img src={require('../../img/innerpage/engage Pages image/Hospitality Banner and images/Group 76309.png')} width="570" height="339" className="img-fluid payroll-img_img" alt="Geofence your Work Locations" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
};

export default HospitalityPage;