import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FeatureCard from '../components/Innerpages/FeatureList';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';

const FeatureList = () => {
    return (
        <div className="body_wrapper">
            {/*<CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>*/}
            <FeatureCard featureClass="feature_area font-36" imgName="innerpage/engage Pages image/Feature page images/Mask Group 50.png" Ptitle="Shift Management "  Pdescription="Create and manage shifts by customizing date and time slots, with great ease and efficiency. The shift management feature also gives you the freedom to arrange it according to the tasks at hand and assign specific individuals or groups to work within them."  PdescriptionTwo="In case of absenteeism or any miscellaneous glitch, you can design a delault shift, so continuity in work is ensured. You also have the flexibility to edit the start ond end points of a shift, if case of a deviation in the original plan. " />
            {/*<Footer FooterData={FooterData}/>*/}
        </div>
    )
}
export default FeatureList;