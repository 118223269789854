import React from "react";
import Fade from "react-reveal/Fade";
import RequestDemoModal from "../RequestDemoModal/RequestDemoModal";

const Excellence = () => {
  return (
    <div className="body_wrapper">
      <section className="bg-white">
        <div className="container pb-md-5 mb-4 h-100">
          <Fade bottom cascade>
            <div className="h-100 py-lg-3 workforce-banner">
              <div className="row align-items-center my-2">
                <div className="col-lg-6 col-md- mb-0 mb-md-0 p-4 ml-0 ml-md-5 ml-lg-5 text-center text-md-left text-lg-left">
                  <h2 className="banner-txt mt-0 mb-4 text-white">
                    Ready to streamline user management and empower your workforce?
                  </h2>
                  <div className="d-flex align-items-center justify-content-center justify-content-md-start justify-content-lg-start">
                    <button
                      data-toggle="modal"
                      data-target="#getDemoBtp"
                      className="btn_three btn-hover mt-2"
                    >
                      Request a Demo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>
      <RequestDemoModal />
    </div>
  );
};

export default Excellence;
