import React from 'react';
import Reveal from 'react-reveal/Reveal/';
import Fade from 'react-reveal/Fade';
import { Helmet } from "react-helmet";

const HealthPage = () => {
    return(
        <>
            <Helmet>
                <title>Employee Management Software for Health Care Units</title>
                <meta
                    name="description"
                    content="Engage is one of the top rated Health care workforce management software to monitor the medical staff activities and time to simplify the admin process."
                />
                <meta
                    name="author"
                    content="Engage"
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Employee Management Software for Health Care Units" />
                <meta property="og:description" content="Engage is one of the top rated Health care workforce management software to monitor the medical staff activities and time to simplify the admin process." />
                <meta property="og:url" content="https://www.engageapp.cloud/" />

                <meta property="og:site_name" content="Engage" />
                <meta property="og:image" content="" />
                <meta property="og:image:width" content="200" />
                <meta property="og:image:height" content="200" />
                <meta property="og:image:alt" content="Employee Management Software for Health Care Units" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="Engage is one of the top rated Health care workforce management software to monitor the medical staff activities and time to simplify the admin process." />
                <meta name="twitter:title" content="Employee Management Software for Health Care Units" />
                <meta name="twitter:site" content="https://www.engageapp.cloud/" />
                <meta name="twitter:creator" content="@engage" />
            </Helmet>
            <div className="sec_pad top_space">
            <section className="healthcare-banner p-0">
                    <div className="row justify-content-end align-items-center flex-row-reverse">
                        <div className="col-xs-12 col-lg-12 justify-content-end">
                            <img src={require('../../img/innerpage/engage Pages image/Healthcare banner & Images/Mask Group 56.png')} width="1519" height="616"className="img-fluid" alt="Health Care Banner"height="512" width="1263"/>
                        </div>
                        {/*<div className="col-xs-12 col-lg-6 seo_sec_title">*/}
                        {/*<div className="max-460">*/}
                            {/*<Reveal effect="fadeInUp" duration={500}>*/}
                            {/*<h1 className="education-title text-uppercase font-weight-semibold">Healthcare</h1></Reveal>*/}
                            {/*<Reveal effect="fadeInUp" duration={800}> <p>Get the team in on time, and keep everything running like clockwork - so your focus can be the wellbeing of patients.</p>*/}
                                {/*/!*<NavLink title="Get start Now- Its free" className="mt-5 seo_btn seo_btn_one btn_hover wow fadeInUp" to="/ScheduleDemo">Get start Now- Its free</NavLink>*!/*/}
                            {/*</Reveal>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                    </div>
            </section>

                {/*<section className="track_attendance sec_pad">*/}
                    {/*<div className="container">*/}
                        {/*<div className="seo_sec_title text-center">*/}
                            {/*<Reveal effect="fadeInUp" duration={1300}><h2>Keep us in the pocket of your scrubs or lab coat</h2></Reveal>*/}
                            {/*<Reveal effect="fadeInUp" duration={1600}><p>Whether physicians are in consultation rooms or making patient rounds, tracking time is easy and accessible with their personal mobile devices. </p></Reveal>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <section className="seo_features_one sec_pad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 d-flex align-items-center  justify-content-end">
                                <Fade bottom cascade>
                                    <div className="seo_features_content max-payroll">
                                        <h1 className="wow fadeInUp mb-3">Transparent Time Tracking</h1>
                                        <p className="wow fadeInUp">In the field of medical science, every second counts. Help your medical and non-medical staff track their time so you can focus on the wellbeing of your patients.</p>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="payroll-img">
                                    <img src={require('../../img/innerpage/engage Pages image/Healthcare banner & Images/Online time clock app for hotels.png')}  className="img-fluid payroll-img_img" alt="Transparent Time Tracking"  width="570" height="345"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="seo_features_one sec_pad pb-0 circle-before">
                    <div className="container">
                        <div className="row flex-row-reverse">
                            <div className="col-lg-6 col-md-12 d-flex align-items-center  ">
                                <Fade bottom cascade>
                                    <div className="seo_features_content max-payroll">
                                        <h2 className="wow fadeInUp mb-3">Geofence your Work Locations</h2>
                                        <p className="wow fadeInUp">Safeguard the security of your health care centre by ensuring the medical fraternity punches in at work from within the hospital premises. The same is encrypted with real-time location tracking and strong facial recognition technology. </p>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 col-md-12 justify-content-end">
                                <div className="payroll-img">
                                    <img src={require('../../img/innerpage/engage Pages image/Healthcare banner & Images/Group 76302.png')}  className="img-fluid payroll-img_img" alt="Geofence your Work Locations"  width="570" height="339"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="seo_features_one sec_pad pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 d-flex align-items-center  justify-content-end">
                                <Fade bottom cascade>
                                    <div className="seo_features_content max-payroll">
                                        <h2 className="mb-3 wow fadeInUp">Apply for leaves, with ease!</h2>

                                        <p className="wow fadeInUp">With a dedicated portal that can be accessed from your phone or system, Engage offers you an easy and systematic approach towards the application and approval of leaves.</p>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="payroll-img">
                                    <img src={require('../../img/innerpage/engage Pages image/Healthcare banner & Images/Group 76303.png')}  className="img-fluid payroll-img_img" alt="Apply for leaves, with ease!" width="570" height="673"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
};

export default HealthPage;