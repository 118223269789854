import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FeatureCard from '../components/Innerpages/FeatureList';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';

const FeatureList = () => {
    return (
        <div className="body_wrapper flex-row-reverse">
            {/*<CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>*/}
            <FeatureCard featureClass="feature_area font-36" imgName="innerpage/engage Pages image/Feature page images/Group 75787.png" Ptitle="Team Management "  Pdescription="Managing a team by defining a team, including and removing an individual from that team and accessing team status. A team manogement feature gives the team leader an easy and intuitive way to manage their team and plan ahead with the help of information which is filtered to just include the team. "  PdescriptionTwo="A team manager can see specific detals obout there team such as online or not, and future leaves a team member is going to toke. This information enables the manager to plan ahead and be on the top of any situation in regards to the team. " />
            {/*<Footer FooterData={FooterData}/>*/}
        </div>
    )
}
export default FeatureList;