import React from 'react';
import Fade from 'react-reveal/Fade';
import { Helmet } from "react-helmet";

const IrisIDPage =()=>{
    return(
        <>
            <Helmet>
                <title>Employee Attendance Management with IrisID login</title>
                <meta
                    name="description"
                    content="IrisID is one of the most secure biometric identification devices and effective solution for attendance and access control to provide effective workflow solution."
                />
                <meta
                    name="author"
                    content="Engage"
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Employee Attendance Management with IrisID login" />
                <meta property="og:description" content="IrisID is one of the most secure biometric identification devices and effective solution for attendance and access control to provide effective workflow solution." />
                <meta property="og:url" content="https://www.engageapp.cloud/" />

                <meta property="og:site_name" content="Engage" />
                <meta property="og:image" content="" />
                <meta property="og:image:width" content="200" />
                <meta property="og:image:height" content="200" />
                <meta property="og:image:alt" content="Employee Attendance Management with IrisID login" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description" content="IrisID is one of the most secure biometric identification devices and effective solution for attendance and access control to provide effective workflow solution." />
                <meta name="twitter:title" content="Employee Attendance Management with IrisID login" />
                <meta name="twitter:site" content="https://www.engageapp.cloud/" />
                <meta name="twitter:creator" content="@engage" />
            </Helmet>
            <section className="iris_area sec_pad">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-7 d-flex align-items-center  ">
                           <Fade bottom cascade>
                               <div className="seo_features_content max-payroll">
                                   <h1 className="mb-3">IrisID</h1>
                                   <p>IrisID, one of the most secure biometric identification devices and effective solution for attendance and access control. The system also integrates calculation for errors which happen while manually calculating hours. With Iris-ID proxy login is not an option.
                                   </p>
                                   <p>The system is already been deployed in thousands of locations and is used by millions daily. This system adds security and  accuracy to the age old attendance process.
                                   </p>
                               </div>
                           </Fade>
                       </div>
                       <div className="col-lg-5 justify-content-end">
                           <div className="payroll-img">
                               <img src={require('../img/innerpage/engage Pages image/IrisID/Group 76310.png')} width="470" height="503" className="img-fluid payroll-img_img" alt="IrisID" />
                           </div>
                       </div>
                   </div>
               </div>
            </section>
        </>
    )
};

export default IrisIDPage;